import { saveData, extractData, clearData } from 'utils/localStorageUtils';

export function saveSession(data) {
    saveData('sessionStethoscope', data);
}

export function extractSession() {
    return extractData('sessionStethoscope');
}

export function clearSession() {
    clearData('sessionStethoscope');
}
