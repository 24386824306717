import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, IconButton, Typography, Paper } from '@material-ui/core';
import { Field, Form, FormSpy } from 'react-final-form';

import { withStyles } from '@material-ui/styles';

import { compareTwoPasswords, composeValidators, password, requiredPassword } from 'validators/validationInputFields';

import TextField from 'inputs/forms/TextField';

import ShowIcon from 'assets/img/open_eye.svg';
import HideIcon from 'assets/img/close_eye.svg';

import { INCORRECT_CREDENTIALS } from 'data/errors';

const styles = () => ({
    icon: {
        height: 24,
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        marginTop: 88,
    },
    paper: {
        width: 600,
        minHeight: 425,
        padding: '20px 10px',
        boxShadow: '0 2px 8px 0 rgba(0,0,0,0.14)',
        borderRadius: 2,
    },
});

const ChangePassword = props => {
    const [isShowPassword, setShowPassword] = useState(false);
    const [isShowNewPassword, setShowNewPassword] = useState(false);
    const [isShowConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleClickShowPassword = useCallback(() => setShowPassword(prevState => !prevState), []);

    const handleClickShowNewPassword = useCallback(() => setShowNewPassword(prevState => !prevState), []);

    const handleClickShowConfirmPassword = useCallback(() => setShowConfirmPassword(prevState => !prevState), []);

    const handleSubmitSuccess = useCallback(({ submitSucceeded }) => {
        if (submitSucceeded) {
            props.onClickCancel();
        }
    }, [props.onClickCancel]);

    return (
        <Box className={props.classes.container}>
            <Paper className={props.classes.paper}>
                <Box my={2} textAlign="center">
                    <Typography variant="h3">
                        {l('Change password')}
                    </Typography>
                </Box>
                <Box mt={4}>
                    <Form onSubmit={props.onClickSave}>
                        {({ submitError, handleSubmit, submitting }) => (
                            <form onSubmit={handleSubmit}>
                                <FormSpy subscription={{ submitSucceeded: true }} onChange={handleSubmitSuccess} />
                                <Box px={12}>
                                    <Field
                                        fullWidth
                                        name="oldPassword"
                                        type={isShowPassword ? 'text' : 'password'}
                                        validate={requiredPassword}
                                        placeholder={l('Current password')}
                                        innerError={submitError === INCORRECT_CREDENTIALS && (
                                            l('Password is incorrect. Please check and try again')
                                        )}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton onClick={handleClickShowPassword}>
                                                    <img
                                                        className={props.classes.icon}
                                                        src={isShowPassword ? ShowIcon : HideIcon}
                                                        alt="show password"
                                                    />
                                                </IconButton>
                                            ),
                                        }}
                                        component={TextField}
                                    />
                                </Box>
                                <Box px={12}>
                                    <Field
                                        fullWidth
                                        name="password"
                                        type={isShowNewPassword ? 'text' : 'password'}
                                        validate={composeValidators(requiredPassword, password)}
                                        placeholder={l('New password')}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton onClick={handleClickShowNewPassword}>
                                                    <img
                                                        className={props.classes.icon}
                                                        src={isShowNewPassword ? ShowIcon : HideIcon}
                                                        alt="show password"
                                                    />
                                                </IconButton>
                                            ),
                                        }}
                                        component={TextField}
                                    />
                                </Box>
                                <Box px={12}>
                                    <Field
                                        fullWidth
                                        name="confirmPassword"
                                        type={isShowConfirmPassword ? 'text' : 'password'}
                                        validate={compareTwoPasswords}
                                        placeholder={l('Repeat new password')}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton onClick={handleClickShowConfirmPassword}>
                                                    <img
                                                        className={props.classes.icon}
                                                        src={isShowConfirmPassword ? ShowIcon : HideIcon}
                                                        alt="show password"
                                                    />
                                                </IconButton>
                                            ),
                                        }}
                                        component={TextField}
                                    />
                                </Box>
                                <Box px={12} mt={4}>
                                    <Button
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={submitting}
                                    >
                                        {l('Confirm')}
                                    </Button>
                                </Box>
                                <Box px={12} mt={2}>
                                    <Button
                                        fullWidth
                                        color="primary"
                                        disabled={submitting}
                                        onClick={props.onClickCancel}
                                    >
                                        {l('Cancel')}
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Form>
                </Box>
            </Paper>
        </Box>
    );
};

ChangePassword.propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onClickSave: PropTypes.func.isRequired,
    onClickCancel: PropTypes.func.isRequired,
};

export default withStyles(styles)(ChangePassword);
