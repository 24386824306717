import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Prompt } from 'react-router-dom';

import ConfirmationDialog from 'components/popups/ConfirmationDialog';

const PromptConfirmationPopup = props => {
    const handleLeave = useCallback(location => {
        if (!/^\/login/.test(location.pathname)) {
            return props.onLeave(location);
        }
        return true;
    }, [props.onLeave]);

    return (
        <Fragment>
            {!props.location && <Prompt when message={handleLeave} />}
            <ConfirmationDialog
                isOpened={props.isOpenPopup}
                okText={props.okText}
                cancelText={props.cancelText}
                onClickOk={props.onClickOk}
                onClickCancel={props.onClickCancel}
                {...props}
            >
                {props.text || props.children}
            </ConfirmationDialog>
        </Fragment>
    );
};

PromptConfirmationPopup.propTypes = {
    okText: PropTypes.string,
    cancelText: PropTypes.string,
    text: PropTypes.string,
    children: PropTypes.node,
    location: ImmutablePropTypes.map,
    isOpenPopup: PropTypes.bool.isRequired,
    onClickOk: PropTypes.func.isRequired,
    onClickCancel: PropTypes.func,
    onLeave: PropTypes.func.isRequired,
};

export default PromptConfirmationPopup;
