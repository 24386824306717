import React, { memo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import MailIcon from '@material-ui/icons/Mail';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import amber from '@material-ui/core/colors/amber';
import green from '@material-ui/core/colors/green';
import { withStyles } from '@material-ui/core/styles';

import { SUCCESS, ERROR, INFO, WARNING, MESSAGE } from 'data/messages';

import settings from 'settings';

const variantIcon = {
    [SUCCESS]: CheckCircleIcon,
    [ERROR]: ErrorIcon,
    [INFO]: InfoIcon,
    [WARNING]: WarningIcon,
    [MESSAGE]: MailIcon,
};

const styles = theme => ({
    [SUCCESS]: {
        marginTop: 5,
        animation: 'slide-up 0.4s ease',
        borderRadius: 5,
        backgroundColor: green[600],
    },
    [ERROR]: {
        animation: 'slide-down 0.4s ease',
        borderRadius: 5,
        backgroundColor: theme.palette.error.dark,
    },
    [INFO]: {
        marginTop: 5,
        animation: 'slide-up 0.4s ease',
        borderRadius: 5,
        backgroundColor: theme.palette.primary.dark,
    },
    [WARNING]: {
        marginTop: 5,
        animation: 'slide-up 0.4s ease',
        borderRadius: 5,
        backgroundColor: amber[700],
    },
    [MESSAGE]: {
        marginTop: 5,
        animation: 'slide-up 0.4s ease',
        borderRadius: 5,
        backgroundColor: theme.palette.grey[100],
        '& span': {
            color: theme.palette.grey[700],
        },
    },
    icon: {
        fontSize: 20,
    },
    content: {
        display: 'flex',
        alignItems: 'center',
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(),
    },
});

const SnackbarMessage = props => {
    const handleClose = useCallback((event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        props.onClose(props.id);
    }, [props.id, props.onClose]);

    useEffect(() => {
        setTimeout(handleClose, settings.delayMessage);
    }, [handleClose]);

    const Icon = variantIcon[props.variant];
    return (
        <SnackbarContent
            className={props.classes[props.variant]}
            message={(
                <Typography component="span" color="inherit" className={props.classes.content}>
                    <Icon className={`${props.classes.icon} ${props.classes.iconVariant}`} />
                    {props.message}
                </Typography>
            )}
            action={[
                <IconButton
                    key="close"
                    color="inherit"
                    className={props.classes.close}
                    onClick={handleClose}
                >
                    <CloseIcon className={props.classes.icon} />
                </IconButton>,
            ]}
        />
    );
};

SnackbarMessage.propTypes = {
    id: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired,
    variant: PropTypes.oneOf([SUCCESS, ERROR, INFO, WARNING, MESSAGE]).isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(memo(SnackbarMessage));
