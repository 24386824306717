import React from 'react';
import PropTypes from 'prop-types';

import { Typography, Button, Box } from '@material-ui/core';

import CenteredWrapper from 'components/wrappers/CenteredWrapper';

import LogoImg from 'assets/img/logo.svg';

const Welcome = props => (
    <CenteredWrapper>
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            flexWrap="wrap"
        >
            <Box mt={4}>
                <img src={LogoImg} alt="logo" />
            </Box>
            <Box mt={4}>
                <Typography variant="h1">
                    {sprintf(l('Welcome, %s!'), props.fullName)}
                </Typography>
            </Box>
            <Box px={12} mt={4} alignSelf="stretch">
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={props.onClickNext}
                >
                    {l('Next')}
                </Button>
            </Box>
        </Box>
    </CenteredWrapper>
);

Welcome.propTypes = {
    fullName: PropTypes.string.isRequired,
    onClickNext: PropTypes.func.isRequired,
};

export default Welcome;
