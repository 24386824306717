import React, { forwardRef, Fragment } from 'react';
import PropTypes from 'prop-types';

import { FormLabel, IconButton, Paper, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Clear as ClearIcon } from '@material-ui/icons';

const useStyles = makeStyles({
    root: {
        position: 'relative',
        boxShadow: 'none',
        display: 'flex',
        paddingBottom: 20,
    },
    iconButton: {
        position: 'absolute',
        bottom: '-2px',
        borderRadius: 4,
        padding: 8,
        right: 0,
        height: 40,
        width: 40,
    },
});

const InputComponent = forwardRef((props, ref) => {
    const {
        label,
        clearable = false,
        error,
        className,
        InputLabelProps = {},
        InputProps = {},
        required,
        onClear,
        inputPadding,
        ...other
    } = props;
    const classes = useStyles({ error, ...other });

    return (
        <Fragment>
            {
                label && (
                    <FormLabel className={`${classes.label}`} required={required}>
                        {label}
                    </FormLabel>
                )
            }
            <Paper className={classes.root}>
                <TextField
                    error={!!error}
                    helperText={error}
                    className={`e2eSuggestInput ${className}`}
                    InputProps={{
                        inputRef: ref,
                        classes: {
                            input: `${classes.input} ${inputPadding}`,
                        },
                        disableUnderline: true,
                        ...InputProps,
                    }}
                    InputLabelProps={{
                        shrink: true,
                        ...InputLabelProps,
                    }}
                    {...other}
                />
                {
                    clearable && (
                        <IconButton
                            type="button"
                            aria-label="search"
                            className={`e2eClear ${classes.iconButton}`}
                            onClick={onClear}
                        >
                            <ClearIcon />
                        </IconButton>
                    )
                }
            </Paper>
        </Fragment>
    );
});

InputComponent.propTypes = {
    clearable: PropTypes.bool,
    required: PropTypes.bool,
    InputLabelProps: PropTypes.object, // eslint-disable-line
    InputProps: PropTypes.object, // eslint-disable-line
    label: PropTypes.string,
    className: PropTypes.string,
    inputPadding: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onBlur: PropTypes.func,
    onClear: PropTypes.func,
};

export default InputComponent;
