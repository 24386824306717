import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = {
    formControl: {
        minWidth: 180,
    },
    toolTip: {
        margin: '5px auto',
        fontFamily: 'Nunito',
    },
};

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 150,
        },
    },
};

const SelectInput = ({
    fullWidth,
    classes,
    className,
    label,
    name,
    withAll,
    values,
    value,
    onChange,
    ...other
}) => (
    <FormControl fullWidth={fullWidth} className={cx(classes.formControl, className)}>
        <InputLabel disableAnimation shrink>{label}</InputLabel>
        <Select
            displayEmpty
            disableUnderline
            value={value}
            inputProps={{ name }}
            MenuProps={MenuProps}
            IconComponent={ExpandMoreIcon}
            disabled={ExpandMoreIcon}
            onChange={onChange}
            {...other}
        >
            {
                withAll && (
                    <MenuItem value="all">
                        <span>{l('All')}</span>
                    </MenuItem>
                )
            }
            {
                values.map(item => (
                    <MenuItem
                        key={item.value} value={item.value}
                    >
                        {item.name}
                    </MenuItem>
                ))
            }
        </Select>
    </FormControl>
);

SelectInput.propTypes = {
    label: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string,
    withAll: PropTypes.bool,
    fullWidth: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    values: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })).isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(memo(SelectInput));
