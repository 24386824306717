import { put, takeLatest } from 'redux-saga/effects';
import { goBack } from 'connected-react-router/immutable';

import { successAction } from 'actions/actionTypes';
import { DELETE_PATIENT } from 'actions/patients';

function* handleDelete() {
    yield put(goBack());
}

export function* watchDeletePatient() {
    yield takeLatest(successAction(DELETE_PATIENT), handleDelete);
}
