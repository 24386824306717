import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';

const EmptyData = props => (
    <Box mt={props.marginOff && 5} m={props.marginDevice && 5} textAlign="center">
        <Typography variant="h3" color="secondary">
            {props.text}
        </Typography>
    </Box>
);

EmptyData.propTypes = {
    text: PropTypes.string.isRequired,
    marginOff: PropTypes.bool,
    marginDevice: PropTypes.bool,
};

export default EmptyData;
