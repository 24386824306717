import { CALL_API } from 'middleware/api';

import { normalizeEntityResponse, normalizePagingResponse } from 'utils/converters';

import schemas from 'data/schemas';

import settings from 'settings';

export const GET_PATIENT = 'GET_PATIENT';
export const GET_PATIENTS = 'GET_PATIENTS';
export const ADD_PATIENT = 'ADD_PATIENT';
export const UPDATE_PATIENT = 'UPDATE_PATIENT';
export const DELETE_PATIENT = 'DELETE_PATIENT';
export const SET_ACTIVE_PATIENT = 'SET_ACTIVE_PATIENT';
export const SHARE_PATIENT = 'SHARE_PATIENT';

export const readPatient = patientId => dispatch => (
    dispatch({
        [CALL_API]: {
            type: GET_PATIENT,
            endpoint: `/api/v1/patients/${patientId}`,
            converter: normalizeEntityResponse(schemas.PATIENT),
        },
    })
);

export const sharePatient = (patientId, values) => dispatch => (
    dispatch({
        patientId,
        [CALL_API]: {
            type: SHARE_PATIENT,
            endpoint: `/api/v1/patients/${patientId}/share`,
            method: 'post',
            body: values,
        },
    })
);

export const addPatient = patient => dispatch => (
    dispatch({
        [CALL_API]: {
            type: ADD_PATIENT,
            endpoint: '/api/v1/patients',
            method: 'post',
            body: patient,
            submitFormRequest: true,
        },
    })
);

export const updatePatient = patient => dispatch => (
    dispatch({
        [CALL_API]: {
            type: UPDATE_PATIENT,
            endpoint: `/api/v1/patients/${patient.id}`,
            method: 'patch',
            body: patient,
            submitFormRequest: true,
        },
    })
);

export const deletePatient = patientId => dispatch => (
    dispatch({
        patientId,
        [CALL_API]: {
            type: DELETE_PATIENT,
            endpoint: `/api/v1/patients/${patientId}`,
            method: 'delete',
        },
    })
);

export const getPatientsData = (
    { params = {}, page = 1, limit = settings.tableEntitiesLimit },
) => dispatch => (
    dispatch({
        params: { page },
        withSpinner: page <= 1,
        [CALL_API]: {
            type: GET_PATIENTS,
            endpoint: '/api/v1/patients',
            params: { ...params, page, limit },
            converter: normalizePagingResponse(schemas.PATIENTS),
        },
    })
);

export const setActivePatient = value => ({
    type: SET_ACTIVE_PATIENT,
    value,
});
