import React, { useCallback, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';

import { Box, Button, IconButton, Typography, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import CenteredWrapper from 'components/wrappers/CenteredWrapper';

import TextField from 'inputs/forms/TextField';

import {
    required,
    password,
    compareTwoPasswords,
    composeValidators,
    requiredPassword,
} from 'validators/validationInputFields';

import ShowIcon from 'assets/img/open_eye.svg';
import HideIcon from 'assets/img/close_eye.svg';

const styles = () => ({
    icon: {
        height: 24,
    },
    send: {
        marginLeft: 8,
        color: '#51B3BD',
        cursor: 'pointer',
    },
});

const ResetPassword = props => {
    const [isShowPassword, setShowPassword] = useState(false);
    const [isShowConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleClickShowPassword = useCallback(() => setShowPassword(prevState => !prevState), []);

    const handleClickShowConfirmPassword = useCallback(() => setShowConfirmPassword(prevState => !prevState), []);

    return (
        <CenteredWrapper>
            <Box my={2} textAlign="center">
                <Typography variant="h1">
                    {l('Reset password')}
                </Typography>
            </Box>
            <Box px={12} mb={2}>
                <Typography>
                    {l('We sent you a code to reset your password. Check your email and enter your code below')}
                </Typography>
            </Box>
            <Box mt={4}>
                <Form onSubmit={props.onClickChangePassword}>
                    {({ submitError, handleSubmit, submitting }) => (
                        <form onSubmit={handleSubmit}>
                            <Box px={12} mb={4}>
                                <Field
                                    fullWidth
                                    name="code"
                                    innerError={submitError ? l('Incorrect code. Please check and try again.') : null}
                                    validate={required}
                                    placeholder={l('Enter your code')}
                                    component={TextField}
                                />
                                <Box display="flex" justifyContent="flex-end">
                                    {
                                        props.resending ? (
                                            <Box mr={2}>
                                                <CircularProgress color="primary" size={18} />
                                            </Box>
                                        ) : (
                                            <Fragment>
                                                <Typography variant="body2">
                                                    {l('Code missing?')}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    className={props.classes.send}
                                                    onClick={props.onClickResendEmail}
                                                >
                                                    {l('Send again')}
                                                </Typography>
                                            </Fragment>
                                        )
                                    }
                                </Box>
                            </Box>
                            <Box px={12}>
                                <Field
                                    fullWidth
                                    name="password"
                                    type={isShowPassword ? 'text' : 'password'}
                                    validate={composeValidators(requiredPassword, password)}
                                    placeholder={l('New password')}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton onClick={handleClickShowPassword}>
                                                <img
                                                    className={props.classes.icon}
                                                    src={isShowPassword ? ShowIcon : HideIcon}
                                                    alt="show password"
                                                />
                                            </IconButton>
                                        ),
                                    }}
                                    component={TextField}
                                />
                            </Box>
                            <Box px={12}>
                                <Field
                                    fullWidth
                                    name="confirmPassword"
                                    type={isShowConfirmPassword ? 'text' : 'password'}
                                    validate={compareTwoPasswords}
                                    placeholder={l('Repeat new password')}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton onClick={handleClickShowConfirmPassword}>
                                                <img
                                                    className={props.classes.icon}
                                                    src={isShowConfirmPassword ? ShowIcon : HideIcon}
                                                    alt="show password"
                                                />
                                            </IconButton>
                                        ),
                                    }}
                                    component={TextField}
                                />
                            </Box>
                            <Box px={12} mt={4}>
                                <Button
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={submitting}
                                >
                                    {l('Confirm')}
                                </Button>
                            </Box>
                            <Box px={12} mt={2}>
                                <Button
                                    fullWidth
                                    color="primary"
                                    disabled={submitting}
                                    onClick={props.onClickCancel}
                                >
                                    {l('Cancel')}
                                </Button>
                            </Box>
                        </form>
                    )}
                </Form>
            </Box>
        </CenteredWrapper>
    );
};

ResetPassword.propTypes = {
    resending: PropTypes.bool,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onClickCancel: PropTypes.func.isRequired,
    onClickResendEmail: PropTypes.func.isRequired,
    onClickChangePassword: PropTypes.func.isRequired,
};

export default withStyles(styles)(ResetPassword);
