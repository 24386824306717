import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';

import { Box, Button, Typography } from '@material-ui/core';

import CenteredWrapper from 'components/wrappers/CenteredWrapper';

import TextField from 'inputs/forms/TextField';

import { composeValidators, email, required } from 'validators/validationInputFields';

const SendEmail = props => (
    <CenteredWrapper>
        <Box my={2} textAlign="center">
            <Typography variant="h1">
                {l('Forgot password')}
            </Typography>
        </Box>
        <Box px={12} mb={2}>
            <Typography>
                {l('Enter the email address you used when creating the account and click the Confirm button')}
            </Typography>
        </Box>
        <Box mt={4}>
            <Form onSubmit={props.onClickSendEmail}>
                {({ submitError, handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit}>
                        <Box px={12} mb={2}>
                            <Field
                                fullWidth
                                name="email"
                                innerError={submitError ? l('User not found') : null}
                                validate={composeValidators(required, email)}
                                placeholder={l('Email')}
                                component={TextField}
                            />
                        </Box>
                        <Box px={12} mt={4}>
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={submitting}
                            >
                                {l('Confirm')}
                            </Button>
                        </Box>
                        <Box px={12} mt={2}>
                            <Button
                                fullWidth
                                color="primary"
                                disabled={submitting}
                                onClick={props.onClickCancel}
                            >
                                {l('Cancel')}
                            </Button>
                        </Box>
                    </form>
                )}
            </Form>
        </Box>
    </CenteredWrapper>
);

SendEmail.propTypes = {
    onClickCancel: PropTypes.func.isRequired,
    onClickSendEmail: PropTypes.func.isRequired,
};

export default SendEmail;
