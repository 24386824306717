import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import { RootRef } from '@material-ui/core';

const UploaderFile = props => {
    const { getRootProps, getInputProps } = useDropzone({
        accept: props.acceptedFiles,
        maxSize: props.maxFileSize,
        multiple: false,
        onDropAccepted: props.onDropAccepted,
        onDropRejected: props.onDropRejected,
    });
    const { ref, ...rootProps } = getRootProps();

    return (
        <RootRef rootRef={ref}>
            <div {...rootProps}>
                {props.children}
                <input {...getInputProps()} />
            </div>
        </RootRef>
    );
};

UploaderFile.propTypes = {
    className: PropTypes.string,
    maxFileSize: PropTypes.number,
    acceptedFiles: PropTypes.string,
    children: PropTypes.node.isRequired,
    onDropAccepted: PropTypes.func.isRequired,
    onDropRejected: PropTypes.func.isRequired,
};

export default UploaderFile;
