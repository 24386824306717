import { STAY } from 'data/prompt';

export const SHOW_PROMPT_POPUP = 'SHOW_PROMPT_POPUP';
export const RESET_PROMPT_POPUP = 'RESET_PROMPT_POPUP';

export const showPromptPopup = location => ({
    type: SHOW_PROMPT_POPUP,
    payload: { location },
});

export const resetPromptPopup = (payload = STAY) => ({
    type: RESET_PROMPT_POPUP,
    payload,
});
