import { fromJS } from 'immutable';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router/immutable';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import api from 'middleware/api';
import window from 'middleware/window';

import rootReducer from 'reducers/index';

import rootSaga from 'sagas';

export default function configureStore(history) {
    const sagaMiddleware = createSagaMiddleware();
    const middlewares = [
        thunk,
        api,
        window,
        sagaMiddleware,
        routerMiddleware(history),
    ];

    const enhancers = [
        composeWithDevTools(applyMiddleware(...middlewares)),
    ];

    const store = createStore(
        rootReducer(history),
        fromJS({}),
        compose(...enhancers),
    );
    sagaMiddleware.run(rootSaga);
    return store;
}
