import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { push, replace } from 'connected-react-router/immutable';
import queryString from 'query-string';

import { getQuery, getPathname } from 'selectors/router';

const mapStateToProps = state => ({
    pathname: getPathname(state),
    query: getQuery(state),
});

const DEFAULT_PARAMS = {
    exclude: [],
    search: '',
};

export default function filterToQuery(WrappedComponent, {
    search = '',
    exclude = [],
} = DEFAULT_PARAMS) {
    function FilterToQuery(props) {
        const filteredQuery = useMemo(() => (
            props.query.filterNot((value, key) => exclude.includes(key))
        ), [props.query, exclude]);

        useEffect(() => {
            if (filteredQuery.isEmpty() && search) {
                const params = {
                    ...props.query.toJS(),
                    ...queryString.parse(search, { arrayFormat: 'bracket-separator' }),
                };
                props.replace({
                    pathname: props.pathname,
                    search: queryString.stringify(
                        params,
                        { arrayFormat: 'bracket-separator' },
                    ),
                });
            }
        }, [props.query, props.pathname, filteredQuery, search]);

        function buildNewQuery(params) {
            return params.reduce((result, current) => {
                const { name, value } = current;
                return (!value && value !== false) || value === 'all' ? result.delete(name) : result.set(name, value);
            }, props.query);
        }

        const handleChangeFilterWithPush = useCallback((...params) => {
            const query = buildNewQuery(params);

            props.push({
                search: queryString.stringify(
                    query.toJS(),
                    { arrayFormat: 'bracket-separator' },
                ),
            });
        });

        const handleChangeFilterWithReplace = useCallback((...params) => {
            const query = buildNewQuery(params);

            props.replace({
                search: queryString.stringify(
                    query.toJS(),
                    { arrayFormat: 'bracket-separator' },
                ),
            });
        });

        return (
            <WrappedComponent
                {...props}
                filteredQuery={filteredQuery}
                onChangeFilterWithPush={handleChangeFilterWithPush}
                onChangeFilterWithReplace={handleChangeFilterWithReplace}
            />
        );
    }

    FilterToQuery.propTypes = {
        query: ImmutablePropTypes.map,
        pathname: PropTypes.string.isRequired,
        push: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired,
    };

    return connect(mapStateToProps, { push, replace })(FilterToQuery);
}
