import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import TextField from 'inputs/forms/TextField';

import { composeValidators, email, required } from 'validators/validationInputFields';

const styles = () => ({
    dialog: {
        zIndex: 999999,
    },
    content: {
        overflowY: 'unset',
        padding: '8px 32px',
    },
    actions: {
        flexDirection: 'column',
        padding: '8px 32px',
    },
});

const SharePatient = props => {
    useEffect(() => {
        if (props.submitSucceeded) {
            props.onClickCancel();
        }
    }, [props.submitSucceeded]);

    return (
        <Dialog
            open
            maxWidth="xs"
            className={props.classes.dialog}
            onClose={props.onClickCancel}
        >
            <form onSubmit={props.handleSubmit}>
                <DialogTitle disableTypography>
                    <Typography>
                        {l('Please, enter email address of the clinician you want to share the Patient with')}
                    </Typography>
                </DialogTitle>
                <DialogContent className={props.classes.content}>
                    <Box mb={2}>
                        <Field
                            fullWidth
                            name="recipientEmail"
                            validate={composeValidators(required, email)}
                            placeholder={l('Clinician’s email')}
                            component={TextField}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        fullWidth
                        type="button"
                        color="primary"
                        variant="text"
                        onClick={props.onClickCancel}
                    >
                        {l('Cancel')}
                    </Button>
                    <Button
                        fullWidth
                        type="submit"
                        color="primary"
                        variant="text"
                    >
                        {l('OK')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

SharePatient.propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    submitSucceeded: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    onClickCancel: PropTypes.func.isRequired,
};

export default withStyles(styles)(SharePatient);
