import React from 'react';
import PropTypes from 'prop-types';

import { Dialog, DialogContent, DialogContentText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
    dialog: {
        zIndex: '999999!important;',
    },
    description: {
        color: '#000',
        fontSize: '16px',
        fontWeight: 'normal',
        textTransform: 'none',
    },
});

const ContactUsDialog = props => (
    <Dialog
        open={props.isOpened}
        className={props.classes.dialog}
        maxWidth={props.maxWidth}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={props.onClickCancel}
    >
        <DialogContent dividers={props.dividers}>
            <DialogContentText
                id="alert-dialog-description"
                component="div"
                className={props.classes.description}
            >
                {props.children}
            </DialogContentText>
        </DialogContent>
    </Dialog>
);

ContactUsDialog.propTypes = {
    maxWidth: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
    ]),
    children: PropTypes.node,
    isOpened: PropTypes.bool.isRequired,
    dividers: PropTypes.bool,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onClickCancel: PropTypes.func,
};

ContactUsDialog.defaultProps = {
    maxWidth: 'sm',
    dividers: false,
};

export default withStyles(styles)(ContactUsDialog);
