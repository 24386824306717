import { fromJS } from 'immutable';

import { SHOW_PROMPT_POPUP, RESET_PROMPT_POPUP } from 'actions/prompt';

const defaultState = fromJS({ isOpen: false });

export default function prompt(state = defaultState, action) {
    switch (action.type) {
        case SHOW_PROMPT_POPUP:
            return fromJS({ isOpen: true, ...action.payload });
        case RESET_PROMPT_POPUP:
            return defaultState;
        default:
            return state;
    }
}
