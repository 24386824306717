import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router/immutable';

import SignUp from 'components/sign-up/SignUp';

import { signUp } from 'actions/users';

import { LOGIN } from 'data/routes';

const mapDispatchToProps = { signUp, replace };

const SignUpContainer = props => {
    const handleClickSignIn = useCallback(() => {
        props.replace(LOGIN);
    }, [props.replace]);

    return (
        <SignUp onClickSignIn={handleClickSignIn} onClickSignUp={props.signUp} />
    );
};

SignUpContainer.propTypes = {
    signUp: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(memo(SignUpContainer));
