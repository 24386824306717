import React from 'react';
import PropTypes from 'prop-types';

import InputDatePicker from 'inputs/InputDatePicker';

const Field = ({
    input,
    meta: { touched, error },
    innerError,
    ...custom
}) => (
    <InputDatePicker
        error={touched ? error || innerError : undefined}
        {...input}
        {...custom}
    />
);

Field.propTypes = {
    input: PropTypes.shape({
        value: PropTypes.string,
        onChange: PropTypes.func.isRequired,
    }).isRequired,
    innerError: PropTypes.string,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }).isRequired,
};

export default Field;
