export const Murmur = 'murmur';
export const S1_S2 = 's1_s2';
export const S3 = 's3';
export const S4 = 's4';
export const Clear = 'clear';
export const Crackles = 'crackles';
export const Rhonchi = 'rhonchi';
export const Stridor = 'stridor';
export const Wheeze = 'wheeze';
export const Unrecognized = 'unrecognized';

export const getResultAreaLungOptions = () => ([
    { name: l('Unrecognized'), value: Unrecognized },
    { name: l('Clear'), value: Clear },
    { name: l('Crackles'), value: Crackles },
    { name: l('Rhonchi'), value: Rhonchi },
    { name: l('Stridor'), value: Stridor },
    { name: l('Wheeze'), value: Wheeze },
]);
export const getResultAreaHeartOptions = () => ([
    { name: l('Unrecognized'), value: Unrecognized },
    { name: l('Murmur'), value: Murmur },
    { name: l('S1_S2'), value: S1_S2 },
    { name: l('S3'), value: S3 },
    { name: l('S4'), value: S4 },
]);
