import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Link } from 'react-router-dom';

import { Box, Typography, Button, IconButton, FormHelperText } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import CenteredWrapper from 'components/wrappers/CenteredWrapper';

import TextField from 'inputs/forms/TextField';

import { email, required, composeValidators, requiredPassword } from 'validators/validationInputFields';

import { RESET_PASSWORD } from 'data/routes';
import { INCORRECT_CREDENTIALS, USER_NOT_FOUND } from 'data/errors';

import ShowIcon from 'assets/img/open_eye.svg';
import HideIcon from 'assets/img/close_eye.svg';

const ERRORS = [INCORRECT_CREDENTIALS, USER_NOT_FOUND];

const styles = () => ({
    link: {
        color: '#51B3BD',
        textDecoration: 'none',
    },
    icon: {
        height: 24,
    },
    formError: {
        fontSize: 14,
    },
});

const Login = props => {
    const [isShowPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = useCallback(() => setShowPassword(prevState => !prevState), []);

    return (
        <CenteredWrapper>
            <Box my={2} textAlign="center">
                <Typography variant="h1">
                    {l('Sign in')}
                </Typography>
            </Box>
            <Box mt={4}>
                <Form onSubmit={props.onClickLogin}>
                    {({ submitError, handleSubmit, submitting }) => (
                        <form onSubmit={handleSubmit}>
                            <Box px={12} mb={2}>
                                <Field
                                    fullWidth
                                    name="email"
                                    innerError={ERRORS.includes(submitError)}
                                    validate={composeValidators(required, email)}
                                    placeholder={l('Email')}
                                    component={TextField}
                                />
                            </Box>
                            <Box px={12}>
                                <Field
                                    fullWidth
                                    name="password"
                                    innerError={ERRORS.includes(submitError)}
                                    type={isShowPassword ? 'text' : 'password'}
                                    validate={requiredPassword}
                                    placeholder={l('Password')}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton onClick={handleClickShowPassword}>
                                                <img
                                                    className={props.classes.icon}
                                                    src={isShowPassword ? ShowIcon : HideIcon}
                                                    alt="show password"
                                                />
                                            </IconButton>
                                        ),
                                    }}
                                    component={TextField}
                                />
                            </Box>
                            <Box px={12} display="flex" justifyContent="flex-end">
                                <Typography>
                                    <Link className={props.classes.link} to={RESET_PASSWORD}>
                                        {l('Forgot Password?')}
                                    </Link>
                                </Typography>
                            </Box>
                            <Box px={12}>
                                {ERRORS.includes(submitError) && (
                                    <FormHelperText error className={props.classes.formError}>
                                        {l('Email or Password is incorrect. Please check and try again')}
                                    </FormHelperText>
                                )}
                            </Box>
                            <Box px={12} mt={4}>
                                <Button
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={submitting}
                                >
                                    {l('Sign in')}
                                </Button>
                            </Box>
                            <Box px={12} mt={2}>
                                <Button
                                    fullWidth
                                    color="primary"
                                    disabled={submitting}
                                    onClick={props.onClickSignUp}
                                >
                                    {l('Sign up')}
                                </Button>
                            </Box>
                            <Box px={12} mt={2}>
                                <Button
                                    fullWidth
                                    color="primary"
                                    disabled={submitting}
                                    onClick={props.onClickContactUs}
                                >
                                    {l('Contact us')}
                                </Button>
                            </Box>
                        </form>
                    )}
                </Form>
            </Box>
        </CenteredWrapper>
    );
};

Login.propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onClickLogin: PropTypes.func.isRequired,
    onClickSignUp: PropTypes.func.isRequired,
    onClickContactUs: PropTypes.func.isRequired,
};

export default withStyles(styles)(Login);
