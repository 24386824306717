import { CALL_API } from 'middleware/api';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const BEGIN_LOGOUT = 'BEGIN_LOGOUT';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const RESTORE_AUTH = 'RESTORE_AUTH';

export const login = credentials => dispatch => (
    dispatch({
        email: credentials.email,
        [CALL_API]: {
            type: LOGIN,
            endpoint: '/api/v1/auth/login',
            method: 'post',
            body: credentials,
            submitFormRequest: true,
        },
    })
);
export const refreshToken = token => dispatch => (
    dispatch({
        [CALL_API]: {
            type: REFRESH_TOKEN,
            endpoint: '/api/v1/auth/token/refresh',
            method: 'post',
            body: { refreshToken: token },
        },
    })
);

export const restoreAuth = appToken => ({
    type: RESTORE_AUTH,
    appToken,
});

export const logout = () => dispatch => (
    dispatch({
        type: LOGOUT,
    })
);

export const beginLogout = () => dispatch => (
    dispatch({
        type: BEGIN_LOGOUT,
    })
);
