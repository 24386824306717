import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { required } from 'validators/validationInputFields';

import SelectField from 'inputs/forms/SelectField';
import TextFieldUploader from 'inputs/forms/TextFieldUploader';

import { getOrganOptions, HEART } from 'data/organs';
import { getAuscultationAreaHeartOptions, getAuscultationAreaLungOptions } from 'data/auscultationArea';

const styles = () => ({
    dialog: {
        zIndex: 999999,
    },
    content: {
        overflowY: 'unset',
        padding: '8px 32px',
    },
    actions: {
        flexDirection: 'column',
        padding: '8px 32px',
    },
});

const AddRecord = props => {
    const organs = useMemo(getOrganOptions, []);
    const auscultationAreaHeartOptions = useMemo(getAuscultationAreaHeartOptions, []);
    const auscultationAreaLungOptions = useMemo(getAuscultationAreaLungOptions, []);

    useEffect(() => {
        if (props.submitSucceeded) {
            props.onClickCancel();
        }
    }, [props.submitSucceeded]);

    const handleChangeOrgan = useCallback(() => {
        props.form.change('auscultationArea', null);
        props.form.change('recognitionResult', null);
    }, [props.form]);

    const handleDropAccepted = useCallback(file => {
        const reader = new FileReader();
        reader.onloadend = () => {
            props.form.change('file', reader.result);
        };
        reader.readAsDataURL(file);
    }, [props.form]);

    return (
        <Dialog open className={props.classes.dialog} onClose={props.onClickCancel}>
            <form onSubmit={props.handleSubmit}>
                <DialogTitle disableTypography>
                    <Typography variant="h3" align="center">
                        {l('Create record')}
                    </Typography>
                </DialogTitle>
                <DialogContent className={props.classes.content}>
                    <Box mb={2}>
                        <Field
                            fullWidth
                            name="organ"
                            validate={required}
                            values={organs}
                            label={l('Organ')}
                            component={SelectField}
                            onChange={handleChangeOrgan}
                        />
                    </Box>
                    <Box mb={2}>
                        <Field
                            fullWidth
                            name="auscultationArea"
                            validate={required}
                            values={
                                props.values.organ === HEART
                                    ? auscultationAreaHeartOptions
                                    : auscultationAreaLungOptions
                            }
                            disabled={!props.values.organ}
                            label={l('Auscultation area')}
                            component={SelectField}
                        />
                    </Box>
                    <Box mb={2}>
                        <Field
                            fullWidth
                            name="fileName"
                            validate={required}
                            placeholder={l('File')}
                            component={TextFieldUploader}
                            onDropAccepted={handleDropAccepted}
                        />
                    </Box>
                </DialogContent>
                <DialogActions className={props.classes.actions}>
                    <Button
                        fullWidth
                        type="submit"
                        color="primary"
                        variant="contained"
                    >
                        {l('Save')}
                    </Button>
                    <Box mt={2} width="100%">
                        <Button
                            fullWidth
                            type="button"
                            color="primary"
                            variant="text"
                            onClick={props.onClickCancel}
                        >
                            {l('Cancel')}
                        </Button>
                    </Box>
                </DialogActions>
            </form>
        </Dialog>
    );
};

AddRecord.propTypes = {
    submitSucceeded: PropTypes.bool,
    form: PropTypes.shape({
        change: PropTypes.func.isRequired,
    }).isRequired,
    values: PropTypes.objectOf(PropTypes.string),
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onClickCancel: PropTypes.func.isRequired,
};

export default withStyles(styles)(AddRecord);
