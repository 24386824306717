import { fromJS, List } from 'immutable';

import {
    CLEAR_RECORDS,
    CREATE_LOCAL_RECORD,
    CREATE_RECORD,
    DELETE_LOCAL_RECORD,
    DELETE_RECORD,
    GET_RECORDS,
    RESTORE_RECORDS,
    SYNC_ALL,
    UPDATE_LOCAL_RECORD,
    UPDATE_RECORD,
} from 'actions/records';
import { failAction, startAction, successAction } from 'actions/actionTypes';

import { SYNCING, SUCCESS } from 'data/syncStatuses';

const defaultState = fromJS({
    ids: [],
    local: [],
    submitting: false,
    syncing: {},
    syncingGlobal: null,
    totalPages: 0,
    totalItems: 0,
    params: {
        page: 1,
    },
});

const localRecords = (state, action) => {
    switch (action.type) {
        case DELETE_LOCAL_RECORD:
            return state.deleteIn(['syncing', action.id]);
        case successAction(DELETE_LOCAL_RECORD): {
            return state.updateIn(['local'], (list = List()) => (
                list.filterNot(item => (item.get('id') === action.id))
            ));
        }
        case UPDATE_LOCAL_RECORD:
            return state.deleteIn(['syncing', action.payload.get('id')]);
        case successAction(UPDATE_LOCAL_RECORD): {
            const { payload } = action;
            const hasInLocal = state.get('local').some(item => item.get('id') === payload.get('id'));
            if (hasInLocal) {
                return state.updateIn(['local'], (list = List()) => (
                    list.map(item => (item.get('id') === payload.get('id') ? payload : item))
                ));
            }
            return state.updateIn(['local'], (list = List()) => list.unshift(action.payload))
                .updateIn(['ids'], (list = List()) => list.filterNot(item => item === payload.get('id')));
        }
        case successAction(CREATE_LOCAL_RECORD):
            return state.updateIn(['local'], (list = List()) => list.unshift(action.payload));
        case RESTORE_RECORDS: {
            const recordIds = action.payload.map(item => item.id);
            return state.set('local', fromJS(action.payload))
                .set('ids', state.get('ids').filterNot(item => recordIds.includes(item)));
        }
        default:
            return state;
    }
};

const records = (state = defaultState, action) => {
    switch (action.type) {
        case CLEAR_RECORDS:
            return defaultState;
        case startAction(SYNC_ALL):
            return state.set('syncingGlobal', SYNCING);
        case successAction(SYNC_ALL):
            return state.set('syncingGlobal', SUCCESS);
        case startAction(GET_RECORDS):
            return state.set('submitting', true);
        case successAction(GET_RECORDS): {
            let ids = List();
            if (action.params.page > 1) {
                ids = state.get('ids');
            }
            const localIds = state.get('local').map(item => item.get('id'));
            const newIds = fromJS(action.response.normalizedData.result).filterNot(item => (
                ids.includes(item) || localIds.includes(item)
            ));
            ids = ids.concat(newIds);
            return state.set('submitting', false)
                .set('ids', ids)
                .set('params', fromJS(action.params))
                .set('totalPages', action.response.data.meta.totalPages)
                .set('totalItems', action.response.data.meta.totalItems);
        }
        case failAction(GET_RECORDS):
            return state.set('submitting', false);
        case successAction(DELETE_RECORD):
            return state.updateIn(['ids'], (list = List()) => list.filterNot(item => item === action.id));
        case startAction(CREATE_RECORD):
        case startAction(UPDATE_RECORD):
            return state.setIn(['syncing', action.id], SYNCING);
        case successAction(CREATE_RECORD):
        case successAction(UPDATE_RECORD): {
            const localIds = state.get('local').filterNot(item => item.get('id') === action.id);
            const ids = state.get('ids').unshift(action.response.normalizedData.result);
            return state.setIn(['syncing', action.response.normalizedData.result], SUCCESS)
                .set('ids', ids)
                .set('local', localIds);
        }
        case failAction(CREATE_RECORD):
        case failAction(UPDATE_RECORD):
            return state.deleteIn(['syncing', action.id]);
        default:
            return localRecords(state, action);
    }
};

export default records;
