import { takeLatest, put } from 'redux-saga/effects';

import { showSpinner, hideSpinner } from 'actions/spinner';
import { startAction, successAction, failAction } from 'actions/actionTypes';

function* handleShowSpinner() {
    yield put(showSpinner);
}

function* handleHideSpinner() {
    yield put(hideSpinner);
}

export function* watchSendRequest() {
    yield takeLatest(action => (
        action.withSpinner && action.type.startsWith(startAction(''))
    ), handleShowSpinner);
}

export function* watchFinishedRequest() {
    yield takeLatest(action => (
        action.withSpinner
        && (
            action.type.startsWith(successAction(''))
            || action.type.startsWith(failAction(''))
        )), handleHideSpinner);
}
