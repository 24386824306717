import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { push } from 'connected-react-router/immutable';
import { Form } from 'react-final-form';

import { getUser } from 'selectors/session';
import { getLocation } from 'selectors/router';
import { getPromptLocation, isOpen } from 'selectors/prompt';

import { updateProfile } from 'actions/users';
import { resetPromptPopup, showPromptPopup } from 'actions/prompt';
import { changePristineForm } from 'actions/finalForms';

import Profile from 'components/profiles/Profile';

import { CHANGE_PASSWORD } from 'data/routes';
import { LEAVE, STAY } from 'data/prompt';

const mapStateToProps = state => ({
    user: getUser(state),
    isOpenPromptConfirmPopup: isOpen(state),
    promptLocation: getPromptLocation(state),
    location: getLocation(state),
});

const mapDispatchToProps = {
    push,
    updateProfile,
    showPromptPopup,
    resetPromptPopup,
    changePristineForm,
};

const ProfileContainer = props => {
    const handleClickSave = useCallback(({ firstName, lastName, phone, email }) => (
        props.updateProfile({ firstName, lastName, phone, email })
    ), [props.updateProfile]);

    const handleClickLeave = useCallback(() => {
        props.resetPromptPopup(LEAVE);
        if (props.promptLocation.get('key') !== props.location.get('key')) {
            props.push(props.promptLocation.toJS());
        }
    }, [props.promptLocation, props.location, props.resetPromptPopup, props.push]);

    const handleClickChangePassword = useCallback(() => {
        props.push(CHANGE_PASSWORD);
    }, [props.push]);

    const handleClickStay = useCallback(() => {
        props.resetPromptPopup(STAY);
    }, [props.resetPromptPopup]);

    return (
        <Form
            component={Profile}
            initialValues={props.user.toJS()}
            isOpenPromptConfirmPopup={props.isOpenPromptConfirmPopup}
            promptLocation={props.promptLocation}
            showPromptPopup={props.showPromptPopup}
            onSubmit={handleClickSave}
            onClickChangePassword={handleClickChangePassword}
            onClickStay={handleClickStay}
            onClickLeave={handleClickLeave}
            onChangePristineForm={props.changePristineForm}
        />
    );
};

ProfileContainer.propTypes = {
    user: ImmutablePropTypes.map.isRequired,
    location: ImmutablePropTypes.map,
    promptLocation: ImmutablePropTypes.map,
    isOpenPromptConfirmPopup: PropTypes.bool,
    push: PropTypes.func.isRequired,
    updateProfile: PropTypes.func.isRequired,
    changePristineForm: PropTypes.func.isRequired,
    showPromptPopup: PropTypes.func.isRequired,
    resetPromptPopup: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(ProfileContainer));
