import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { InputAdornment, CircularProgress } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { makeStyles } from '@material-ui/styles';

import TextField from 'inputs/forms/TextField';

import UploaderFile from 'components/UploaderFile';

import { formatFileSize } from 'utils/formatterUtils';

import settings from 'settings';

const useStyles = makeStyles({
    root: {
        color: '#51B3BD',
    },
});

const TextFieldUploaderAdornment = ({ position, loading, loadingSize, attachIconSize }) => {
    const classes = useStyles();

    return (
        <InputAdornment position={position}>
            {loading && <CircularProgress size={loadingSize} classes={classes} />}
            <AttachFileIcon fontSize={attachIconSize} classes={classes} />
        </InputAdornment>
    );
};

TextFieldUploaderAdornment.propTypes = {
    position: PropTypes.string,
    loading: PropTypes.bool,
    loadingSize: PropTypes.number,
    attachIconSize: PropTypes.string,
};

TextFieldUploaderAdornment.defaultProps = {
    position: 'end',
    loading: false,
    loadingSize: 15,
    attachIconSize: 'small',
};

const TextFieldUploader = props => {
    const [error, setError] = useState();

    const { label, input, onDropAccepted, ...other } = props;

    const handleDropAccepted = useCallback(files => {
        setError(null);
        if (files && files.length) {
            onDropAccepted(files[0]);
            input.onChange(files[0].name);
        }
    }, [input, onDropAccepted]);

    const handleDropRejected = useCallback(files => {
        const fileError = files[0].errors[0];

        if (fileError.code === 'file-invalid-type') {
            setError(sprintf(l('File type must be %s'), settings.acceptedFiles));
        } else if (fileError.code === 'file-too-large') {
            setError(sprintf(l('Upload file size maximum is %s'),
                formatFileSize(settings.maxFileSize / 1024)));
        } else {
            setError(l('File is not acceptable'));
        }
    }, []);

    return (
        <UploaderFile
            acceptedFiles={settings.acceptedFiles}
            maxFileSize={settings.maxFileSize}
            onDropAccepted={handleDropAccepted}
            onDropRejected={handleDropRejected}
        >
            <TextField
                innerError={error}
                label={label}
                InputProps={{
                    readOnly: true,
                    endAdornment: <TextFieldUploaderAdornment />,
                }}
                input={input}
                {...other}
            />
        </UploaderFile>
    );
};

TextFieldUploader.propTypes = {
    label: PropTypes.string,
    input: PropTypes.shape({
        value: PropTypes.string,
        onChange: PropTypes.func.isRequired,
    }),
    isLoading: PropTypes.bool,
    innerError: PropTypes.string,
    onDropAccepted: PropTypes.func.isRequired,
};

export default TextFieldUploader;
