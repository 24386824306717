import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router/immutable';

import { confirmEmail, resendConfirmationEmail } from 'actions/users';

import { getEmail, getIsResending } from 'selectors/confirmEmail';

import ConfirmEmail from 'components/sign-up/ConfirmEmail';

import { LOGIN } from 'data/routes';

const mapStateToProps = state => ({
    email: getEmail(state),
    resending: getIsResending(state),
});

const mapDispatchToProps = { confirmEmail, resendConfirmationEmail, replace };

const ConfirmEmailContainer = props => {
    const handleClickConfirm = useCallback(values => (
        props.confirmEmail({ email: props.email, code: values.code })
    ), [props.email, props.confirmEmail]);

    const handleClickResendConfirmation = useCallback(() => (
        props.resendConfirmationEmail(props.email)
    ), [props.email, props.resendConfirmationEmail]);

    const handleClickCancel = useCallback(() => (
        props.replace(LOGIN)
    ), [props.replace]);

    return (
        <ConfirmEmail
            email={props.email}
            resending={props.resending}
            onClickCancel={handleClickCancel}
            onClickConfirm={handleClickConfirm}
            onClickResendConfirmation={handleClickResendConfirmation}
        />
    );
};

ConfirmEmailContainer.propTypes = {
    resending: PropTypes.bool,
    email: PropTypes.string.isRequired,
    replace: PropTypes.func.isRequired,
    confirmEmail: PropTypes.func.isRequired,
    resendConfirmationEmail: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(ConfirmEmailContainer));
