import { fromJS, List } from 'immutable';

import {
    ADD_PATIENT,
    GET_PATIENTS,
    SET_ACTIVE_PATIENT,
} from 'actions/patients';
import { failAction, startAction, successAction } from 'actions/actionTypes';

const defaultState = fromJS({
    ids: [],
    submitting: false,
    params: {
        page: 1,
    },
    totalPages: 0,
    activePatient: 0,
});
const patients = (state = defaultState, action) => {
    switch (action.type) {
        case startAction(GET_PATIENTS):
            return state.set('submitting', true);
        case successAction(GET_PATIENTS): {
            let ids = List();
            if (action.params.page > 1) {
                ids = state.get('ids');
            }
            const newIds = fromJS(action.response.normalizedData.result).filterNot(item => (
                ids.includes(item)
            ));
            ids = ids.concat(newIds);
            return state.set('submitting', false)
                .set('ids', ids)
                .set('params', fromJS(action.params))
                .set('totalPages', action.response.data.meta.totalPages);
        }
        case failAction(GET_PATIENTS):
            return state.set('submitting', false);
        case startAction(ADD_PATIENT):
            return state.set('submitting', true);
        case successAction(ADD_PATIENT):
            return state.set('submitting', false);
        case failAction(ADD_PATIENT):
            return state.set('submitting', false);
        case SET_ACTIVE_PATIENT:
            return state.set('activePatient', action.value);
        default:
            return state;
    }
};

export default patients;
