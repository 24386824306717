import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router/immutable';
import ContactUs from 'components/contact-us/ContactUs';

import { LOGIN } from 'data/routes';

const mapDispatchToProps = { push };

const ContactUsContainer = props => {
    const handleClickBack = useCallback(() => {
        props.push(LOGIN);
    }, [props.push]);

    return (
        <ContactUs
            onClickLogin={handleClickBack}
        />
    );
};

ContactUsContainer.propTypes = {
    push: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(memo(ContactUsContainer));
