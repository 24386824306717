import React, { memo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { Map, List, fromJS } from 'immutable';
import { Form } from 'react-final-form';
import { push, goBack } from 'connected-react-router/immutable';

import Notes from 'components/notes/Notes';

import { getLocation } from 'selectors/router';
import { getFullName } from 'selectors/session';
import { getRecordById } from 'selectors/records';
import { getPromptLocation, isOpen } from 'selectors/prompt';

import { changePristineForm } from 'actions/finalForms';
import { showPromptPopup, resetPromptPopup } from 'actions/prompt';
import { getRecord, restoreRecords, updateLocalRecord } from 'actions/records';

import { STAY } from 'data/prompt';

const mapStateToProps = (state, ownProps) => {
    const recordId = ownProps.match && ownProps.match.params.recordId;
    const record = getRecordById(state)(recordId);
    return ({
        record,
        recordId,
        isOpenPromptConfirmPopup: isOpen(state),
        promptLocation: getPromptLocation(state),
        location: getLocation(state),
        doctorFullName: getFullName(state),
    });
};

const mapDispatchToProps = {
    push,
    goBack,
    getRecord,
    restoreRecords,
    showPromptPopup,
    resetPromptPopup,
    updateLocalRecord,
    changePristineForm,
};

const NotesContainer = props => {
    useEffect(props.restoreRecords, []);

    useEffect(() => {
        if (!props.record) {
            props.getRecord(props.recordId);
        }
    }, [props.record, props.recordId]);

    const handleClickLeave = useCallback(() => {
        props.resetPromptPopup();
        if (props.promptLocation.get('key') !== props.location.get('key')) {
            props.push(props.promptLocation.toJS());
        }
    }, [props.promptLocation, props.location, props.resetPromptPopup, props.push]);

    const handleClickSubmit = useCallback(values => {
        const record = props.record.updateIn(['notes'], (list = List()) => (
            list.isEmpty()
                ? list.push(fromJS({ comment: values.comment }))
                : List.of(list.first().set('comment', values.comment))
        ));
        return props.updateLocalRecord(record.set('isConfirmed', false));
    }, [props.record]);

    const handleClickStay = useCallback(() => {
        props.resetPromptPopup(STAY);
    }, [props.resetPromptPopup]);

    if (!props.record || props.record.isEmpty()) {
        return null;
    }
    const comment = props.record.get('notes').first(Map()).get('comment');
    return (
        <Form
            component={Notes}
            patient={props.record.get('patient')}
            promptLocation={props.promptLocation}
            doctorFullName={props.doctorFullName}
            initialValues={{ comment }}
            goBack={props.goBack}
            onSubmit={handleClickSubmit}
            isOpenPromptConfirmPopup={props.isOpenPromptConfirmPopup}
            showPromptPopup={props.showPromptPopup}
            onClickStay={handleClickStay}
            onClickLeave={handleClickLeave}
            onChangePristineForm={props.changePristineForm}
        />
    );
};

NotesContainer.propTypes = {
    isOpenPromptConfirmPopup: PropTypes.bool,
    location: ImmutablePropTypes.map,
    record: ImmutablePropTypes.map,
    promptLocation: ImmutablePropTypes.map,
    recordId: PropTypes.string.isRequired,
    doctorFullName: PropTypes.string.isRequired,
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    getRecord: PropTypes.func.isRequired,
    restoreRecords: PropTypes.func.isRequired,
    showPromptPopup: PropTypes.func.isRequired,
    resetPromptPopup: PropTypes.func.isRequired,
    updateLocalRecord: PropTypes.func.isRequired,
    changePristineForm: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(NotesContainer));
