import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';

import SharePatient from 'components/patients/SharePatient';

import { sharePatient } from 'actions/patients';

const mapDispatchToProps = { sharePatient };

const SharePatientContainer = props => {
    const handleClickSave = useCallback(values => (
        props.sharePatient(props.patientId, values)
    ), [props.patientId, props.sharePatient]);

    return (
        <Form
            component={SharePatient}
            onSubmit={handleClickSave}
            onClickCancel={props.onClickCancel}
        />
    );
};

SharePatientContainer.propTypes = {
    patientId: PropTypes.string.isRequired,
    sharePatient: PropTypes.func.isRequired,
    onClickCancel: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(memo(SharePatientContainer));
