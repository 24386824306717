export const MALE = 'male';
export const FEMALE = 'female';

export const getSex = () => ({
    [MALE]: l('Male'),
    [FEMALE]: l('Female'),
});

export const getSexOptions = () => ([
    { name: l('Male'), value: MALE },
    { name: l('Female'), value: FEMALE },
]);
