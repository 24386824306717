import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { List } from 'immutable';

import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    emptyValue: {
        color: theme.palette.text.disabled,
    },
});

const Field = ({
    input,
    label,
    id,
    name,
    values,
    emptyValue,
    classes,
    className,
    disabled,
    required,
    fullWidth,
    multiple = false,
    meta: { touched, error },
}) => (
    <FormControl fullWidth={fullWidth} error={touched && !!error} className={className}>
        {
            label && (
                <InputLabel
                    disabled={disabled}
                    required={required}
                    shrink htmlFor={id}
                >
                    {label}
                </InputLabel>
            )
        }
        <Select
            disabled={disabled}
            displayEmpty
            disableUnderline
            className={cx(emptyValue && (!input.value || input.value.isEmpty()) && classes.emptyValue)}
            value={input.value || ''}
            inputProps={{ ...input, value: List.isList(input.value) ? input.value.toJS() : input.value, name, id }}
            multiple={multiple}
            IconComponent={ExpandMoreIcon}
            onChange={input.onChange}
        >
            {
                emptyValue && (!input.value || input.value.isEmpty()) && (
                    <MenuItem className={cx('e2eEmptyValue', classes.emptyValue)} value="" disabled>
                        {emptyValue}
                    </MenuItem>
                )
            }
            {
                values.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                        {item.name}
                    </MenuItem>
                ))
            }
        </Select>
        {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
);

Field.propTypes = {
    input: PropTypes.object, // eslint-disable-line
    customStyles: PropTypes.object, // eslint-disable-line
    label: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    emptyValue: PropTypes.string,
    className: PropTypes.string,
    meta: PropTypes.object, // eslint-disable-line
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    values: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })).isRequired,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    required: PropTypes.bool,
    multiple: PropTypes.bool,
};

export default withStyles(styles)(Field);
