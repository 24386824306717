import { CALL_API } from 'middleware/api';

import { getUserId } from 'selectors/session';

import { extractData } from 'utils/localStorageUtils';
import { normalizeEntityResponse, normalizePagingResponse } from 'utils/converters';

import schemas from 'data/schemas';

import { checkUUID } from 'validators/validationInputFields';

import settings from 'settings';

export const GET_RECORD = 'GET_RECORD';
export const GET_RECORDS = 'GET_RECORDS';
export const DELETE_RECORD = 'DELETE_RECORD';
export const DELETE_LOCAL_RECORD = 'DELETE_LOCAL_RECORD';
export const UPDATE_LOCAL_RECORD = 'UPDATE_LOCAL_RECORD';
export const CREATE_LOCAL_RECORD = 'CREATE_LOCAL_RECORD';
export const RESTORE_RECORDS = 'RESTORE_RECORDS';
export const CREATE_RECORD = 'CREATE_RECORD';
export const UPDATE_RECORD = 'UPDATE_RECORD';
export const SYNC_ALL = 'SYNC_ALL';
export const CLEAR_RECORDS = 'CLEAR_RECORDS';

export const getRecordsData = ({ params = {}, page = 1, limit = settings.tableEntitiesLimit }) => dispatch => (
    dispatch({
        withSpinner: page <= 1,
        params: { page },
        [CALL_API]: {
            type: GET_RECORDS,
            endpoint: '/api/v1/records',
            params: { limit, page, ...params },
            converter: normalizePagingResponse(schemas.RECORDS),
        },
    })
);

export const getRecord = recordId => dispatch => {
    if (typeof recordId === 'number' || !checkUUID(recordId)) {
        return null;
    }
    return (
        dispatch({
            withSpinner: true,
            [CALL_API]: {
                type: GET_RECORD,
                endpoint: `/api/v1/records/${recordId}`,
                converter: normalizeEntityResponse(schemas.RECORD),
            },
        })
    );
};

export const deleteRecord = record => dispatch => (
    dispatch({
        id: record.get('id'),
        [CALL_API]: {
            type: DELETE_RECORD,
            endpoint: `/api/v1/records/${record.get('id')}`,
            method: 'delete',
        },
    })
);

export const deleteLocalRecord = record => dispatch => (
    dispatch({
        type: DELETE_LOCAL_RECORD,
        id: record.get('id'),
    })
);

export const addRecord = record => dispatch => (
    dispatch({
        type: CREATE_LOCAL_RECORD,
        payload: record,
    })
);

export const updateLocalRecord = record => dispatch => (
    dispatch({
        type: UPDATE_LOCAL_RECORD,
        payload: record,
    })
);

export const clearRecords = () => dispatch => (
    dispatch({ type: CLEAR_RECORDS })
);

export const restoreRecords = () => (dispatch, getState) => {
    const userId = getUserId(getState());
    let data = extractData('recordsStethoscope');
    if (data) {
        data = JSON.parse(data);
        data = data[userId] || [];
    } else {
        data = [];
    }
    return (
        dispatch({
            type: RESTORE_RECORDS,
            payload: data,
        })
    );
};

export const createRecord = record => dispatch => {
    const data = Object.keys(record).filter(key => record[key]).reduce((result, key) => {
        if (Array.isArray(record[key])) {
            record[key].forEach(item => result.append(`${key}[]`, item));
        } else {
            result.append(key, record[key]);
        }
        return result;
    }, new FormData());
    return dispatch({
        id: record.id,
        [CALL_API]: {
            type: CREATE_RECORD,
            endpoint: '/api/v1/records',
            method: 'post',
            headers: { 'Content-Type': 'multipart/form-data' },
            body: data,
            converter: normalizeEntityResponse(schemas.RECORD),
            submitFormRequest: true,
        },
    });
};

export const updateRecord = ({ id, ...record }) => dispatch => (
    dispatch({
        id,
        [CALL_API]: {
            type: UPDATE_RECORD,
            endpoint: `/api/v1/records/${id}`,
            method: 'patch',
            body: {
                ...record,
                patientId: record.patient && record.patient.id,
                notes: record.notes.map(item => item.comment),
            },
            converter: () => normalizeEntityResponse(schemas.RECORD)({ data: { id, ...record } }),
        },
    })
);

export const syncAll = records => dispatch => (
    dispatch({
        type: SYNC_ALL,
        payload: records,
    })
);
