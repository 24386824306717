import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';

import session from 'reducers/session';
import entities from 'reducers/entities';
import resetPassword from 'reducers/resetPassword';
import confirmEmail from 'reducers/confirmEmail';
import messages from 'reducers/messages';
import welcome from 'reducers/welcome';
import records from 'reducers/records';
import spinner from 'reducers/spinner';
import prompt from 'reducers/prompt';
import patients from 'reducers/patients';
import devices from 'reducers/devices';
import finalForms from 'reducers/finalForms';

import { LOGOUT } from 'actions/session';

const appReducer = history => combineReducers({
    session,
    entities,
    messages,
    resetPassword,
    confirmEmail,
    welcome,
    records,
    patients,
    devices,
    spinner,
    prompt,
    finalForms,
    router: connectRouter(history),
});

const rootReducer = history => (state, action) => {
    if (action.type === LOGOUT) {
        return appReducer(history)(undefined, action);
    }
    return appReducer(history)(state, action);
};

export default rootReducer;
