import React, { useState, useCallback, useLayoutEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { AppBar, Box, Toolbar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import HeaderMenu from 'components/header/HeaderMenu';

import { ICON_WIDTH } from 'data/header';
import { RECORDS } from 'data/routes';

import LogoImg from 'assets/img/logo.svg';
import NoAvatarImg from 'assets/img/no_avatar.svg';
import { getHeaderTabs } from '../../data/headerTabs';
import ContactUsModal from '../contact-us/ContactUsModal';

const styles = () => ({
    logoWrapper: {
        display: 'flex',
        marginLeft: '5%',
        marginRight: 16,
        padding: '5px 0',
    },
    link: {
        textDecoration: 'none',
    },
    logo: {
        display: 'contents',
        textDecoration: 'none',
        '& img': {
            maxWidth: 150,
            maxHeight: 70,
            objectFit: 'contain',
        },
    },
    appBar: {
        position: 'relative',
        zIndex: 10000,
        '& header': {
            boxShadow: '0 2px 8px 0 rgba(0,0,0,0.09)',
        },
    },
    toolbar: {
        minHeight: 80,
    },
    tab: {
        padding: '10px 2px',
        marginLeft: '3%',
        marginRight: '3%',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, .1)',
        },
    },
    avatar: {
        cursor: 'pointer',
    },
    active: {
        borderBottom: '2px solid #51B3BD',
    },
});

const Header = props => {
    const targetRefName = useRef();
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuWidth, setMenuWidth] = useState(0);
    const [visibleContactUs, setVisibleContactUs] = useState(false);

    const tabs = useMemo(getHeaderTabs, []);

    let dimensionsNameResult;
    const dimensionsBlockResult = ICON_WIDTH;

    useLayoutEffect(() => {
        if (targetRefName.current) {
            dimensionsNameResult = targetRefName.current.offsetWidth;
        }
    }, []);

    const handleClick = useCallback(event => {
        setMenuWidth(dimensionsBlockResult + dimensionsNameResult);
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClickProfile = useCallback(() => {
        props.onClickProfile();
        setAnchorEl(null);
    }, [props.onClickProfile]);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleOpenContactUs = useCallback(() => {
        setVisibleContactUs(true);
    }, []);

    const handleCloseContactUs = useCallback(() => {
        setVisibleContactUs(false);
    }, []);

    return (
        <div className={props.classes.appBar}>
            <AppBar position="static" color="primary">
                <Toolbar className={props.classes.toolbar}>
                    <Box className={props.classes.logoWrapper}>
                        <Link to={RECORDS} className={props.classes.logo}>
                            <img src={LogoImg} alt="Logo" />
                        </Link>
                    </Box>
                    {
                        tabs.map(item => (
                            <Box
                                key={item.key}
                                className={cx(props.classes.tab, {
                                    [props.classes.active]: item.regExpSelection.test(props.pathName),
                                })}
                            >
                                <Link to={item.path} className={props.classes.link}>
                                    <Typography color="secondary">
                                        {item.label}
                                    </Typography>
                                </Link>
                            </Box>
                        ))
                    }
                    {anchorEl && (
                        <HeaderMenu
                            anchorEl={anchorEl}
                            menuWidth={menuWidth}
                            onClickClose={handleClose}
                            onClickLogout={props.onClickLogout}
                            onClickProfile={handleClickProfile}
                            onClickContactUs={handleOpenContactUs}
                        />
                    )}
                    <Box
                        ml="auto"
                        ref={targetRefName}
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                    >
                        <Typography color="secondary">
                            {props.fullName}
                        </Typography>
                    </Box>
                    <Box ml={2} mr="5%" onClick={handleClick}>
                        <img className={props.classes.avatar} src={NoAvatarImg} alt="NoAvatar" />
                    </Box>
                </Toolbar>
            </AppBar>
            <ContactUsModal isOpened={visibleContactUs} onClickCancel={handleCloseContactUs} />
        </div>
    );
};

Header.propTypes = {
    fullName: PropTypes.string.isRequired,
    pathName: PropTypes.string.isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onClickLogout: PropTypes.func.isRequired,
    onClickProfile: PropTypes.func.isRequired,
};

export default withStyles(styles)(Header);
