import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import MomentUtils from '@date-io/moment';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { createBrowserHistory } from 'history';
import { setLocaleData, detectUserLocale } from 'i18n';
import theme from 'theme';

import configureStore from 'store';

import { restoreAuth } from 'actions/session';

import App from 'components/App';

import { extractSession } from 'utils/session';

import 'assets/style/main.css';

const history = createBrowserHistory();
const store = configureStore(history);
const customTheme = createMuiTheme(theme);

const renderApp = () => {
    ReactDOM.render(
        <Provider store={store}>
            <MuiThemeProvider theme={customTheme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <App history={history} />
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        </Provider>,
        document.getElementById('app'),
    );
};

const startApp = () => {
    const session = extractSession();
    if (session) {
        store.dispatch(restoreAuth(session));
    }
    setLocaleData(detectUserLocale()).then(() => renderApp());
};

startApp();
