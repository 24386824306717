import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import MessageStacks from 'components/messages/MessageStacks';

import { hideMessage } from 'actions/messages';

import { getMessages } from 'selectors/messages';

const mapStateToProps = state => ({
    messages: getMessages(state),
});

const SnackbarMessagesContainer = props => {
    if (props.messages.isEmpty()) {
        return null;
    }
    return (
        <MessageStacks
            messages={props.messages}
            hideMessage={props.hideMessage}
        />
    );
};

SnackbarMessagesContainer.propTypes = {
    messages: ImmutablePropTypes.list,
    hideMessage: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { hideMessage })(memo(SnackbarMessagesContainer));
