import React, { memo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { push, go } from 'connected-react-router/immutable';
import { fromJS } from 'immutable';

import { getPatientsData, setActivePatient } from 'actions/patients';
import { updateLocalRecord } from 'actions/records';

import { getIsSubmitting, getPage, getPatients, hasNext } from 'selectors/patients';
import { getQuery, getRouteState } from 'selectors/router';
import { getUserId } from 'selectors/session';

import Patients from 'components/patients/Patients';

import { ADD_PATIENT } from 'data/routes';

const mapStateToProps = state => ({
    patients: getPatients(state),
    query: getQuery(state),
    hasNext: hasNext(state),
    userId: getUserId(state),
    page: getPage(state),
    isSubmitting: getIsSubmitting(state),
    record: getRouteState(state) && getRouteState(state).get('record'),
});

const mapDispatchToProps = {
    push,
    go,
    getPatientsData,
    setActivePatient,
    updateLocalRecord,
};

const PatientsContainer = props => {
    useEffect(() => {
        props.getPatientsData({ params: props.query.toJS() });
    }, [props.query]);

    const handleClickAddPatient = useCallback(() => {
        props.push(ADD_PATIENT);
    }, [props.push]);

    const handleClickHistory = useCallback(patient => {
        props.push(`/patients/${patient.get('id')}/records`);
    }, [props.push]);

    const handleClickProfile = useCallback(patient => {
        props.push(`/patients/${patient.get('id')}`);
    }, [props.push]);

    const handleClickAssign = useCallback(patient => {
        const record = props.record.merge(fromJS({ patient, isConfirmed: false }));
        props.updateLocalRecord(record);
        props.go(-2);
    }, [props.record, props.go, props.updateLocalRecord]);

    const handleReadNext = useCallback(() => {
        if (!props.isSubmitting) {
            props.getPatientsData({ page: props.page + 1, params: props.query.toJS() });
        }
    }, [props.isSubmitting, props.page, props.getPatientsData]);

    return (
        <Patients
            hasNext={props.hasNext}
            withAssign={!!props.record}
            patients={props.patients}
            userId={props.userId}
            onReadNext={handleReadNext}
            onClickAssign={handleClickAssign}
            onClickHistory={handleClickHistory}
            onClickProfile={handleClickProfile}
            onClickAddPatient={handleClickAddPatient}
        />
    );
};

PatientsContainer.propTypes = {
    record: ImmutablePropTypes.map,
    hasNext: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    page: PropTypes.number.isRequired,
    userId: PropTypes.string.isRequired,
    patients: ImmutablePropTypes.list,
    query: ImmutablePropTypes.map.isRequired,
    push: PropTypes.func.isRequired,
    go: PropTypes.func.isRequired,
    getPatientsData: PropTypes.func.isRequired,
    updateLocalRecord: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(PatientsContainer));
