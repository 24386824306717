import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';

const styles = () => ({
    container: {
        position: 'fixed',
        width: '100%',
        height: 'calc(100vh - 82px)',
        top: 82,
        zIndex: 99990,
        textAlign: 'center',
        display: 'flex',
        columns: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#F9F9F9',
    },
});

const Spinner = props => (
    props.isShowSpinner ? (
        <div className={props.classes.container}>
            <CircularProgress color="primary" size={64} />
        </div>
    ) : null
);

Spinner.propTypes = {
    isShowSpinner: PropTypes.bool,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(Spinner);
