import { fromJS } from 'immutable';

import { CHANGE_PRISTINE_FORM } from 'actions/finalForms';

const defaultState = fromJS({
    pristine: true,
});

const finalForms = (state = defaultState, action) => {
    switch (action.type) {
        case CHANGE_PRISTINE_FORM:
            return state.set('pristine', action.payload.pristine);
        default:
            return state;
    }
};

export default finalForms;
