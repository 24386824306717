import { CALL_API } from 'middleware/api';

export const SIGN_UP = 'SIGN_UP';
export const CONFIRM_EMAIL = 'CONFIRM_EMAIL';
export const SET_CONFIRM_DATA = 'SET_CONFIRM_DATA';
export const RESEND_CONFIRM_CODE = 'RESEND_CONFIRM_CODE';
export const CLEAR_CONFIRM_DATA = 'CLEAR_CONFIRM_DATA';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

export const signUp = body => dispatch => (
    dispatch({
        email: body.email,
        lastName: body.lastName,
        firstName: body.firstName,
        [CALL_API]: {
            type: SIGN_UP,
            endpoint: '/api/v1/auth/signup',
            method: 'post',
            body,
            submitFormRequest: true,
        },
    })
);

export const confirmEmail = body => dispatch => (
    dispatch({
        email: body.email,
        [CALL_API]: {
            type: CONFIRM_EMAIL,
            endpoint: '/api/v1/auth/signup/confirm',
            method: 'post',
            body,
            submitFormRequest: true,
        },
    })
);

export const resendConfirmationEmail = email => dispatch => (
    dispatch({
        email,
        [CALL_API]: {
            type: RESEND_CONFIRM_CODE,
            endpoint: '/api/v1/auth/signup/resend',
            method: 'post',
            body: { email },
        },
    })
);

export const updateProfile = profile => dispatch => (
    dispatch({
        [CALL_API]: {
            type: UPDATE_PROFILE,
            endpoint: '/api/v1/users/self',
            method: 'patch',
            body: profile,
        },
    })
);

export const changePassword = values => dispatch => (
    dispatch({
        [CALL_API]: {
            type: CHANGE_PASSWORD,
            endpoint: '/api/v1/auth/password/change',
            method: 'post',
            body: { oldPassword: values.oldPassword, newPassword: values.password },
            submitFormRequest: true,
        },
    })
);

export const setConfirmData = payload => dispatch => (
    dispatch({
        type: SET_CONFIRM_DATA,
        payload,
    })
);

export const clearConfirmData = () => dispatch => (
    dispatch({
        type: CLEAR_CONFIRM_DATA,
    })
);
