import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import cx from 'classnames';
import moment from 'moment';

import { Box, Button, Container, IconButton, Typography } from '@material-ui/core';
import { ArrowBackIos as ArrowBackIcon, Clear as ClearIcon, Sync as SyncIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';

import SyncSuccessIcon from 'assets/img/success_sync.svg';
import DisabledSyncSuccessIcon from 'assets/img/disabled_success_sync.svg';
import ImportIcon from 'assets/img/import.svg';
import FilterIcon from 'assets/img/filter.svg';
import ShareIcon from 'assets/img/share.svg';

import { SUCCESS, SYNCING } from 'data/syncStatuses';

import { DATE } from 'utils/formatterUtils';

const styles = () => ({
    logoWrapper: {
        display: 'flex',
        marginLeft: '5%',
        marginRight: 16,
        padding: '5px 0',
    },
    iconButton: {
        fontWeight: 600,
        padding: '6px 28px',
    },
    rotate: {
        animationName: 'rotation',
        animationDuration: '1s',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'linear',
    },
    opacity: {
        opacity: '0.5',
    },
    clearFiltersButton: {
        color: '#fff',
        backgroundColor: '#CF5C36',
        borderRadius: 5,
        width: 44,
        height: 44,
        marginLeft: 8,
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), ' +
            '0px 2px 2px 0px rgb(0 0 0 / 14%), ' +
            '0px 1px 5px 0px rgb(0 0 0 / 12%)',
    },
    filterContainer: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
    },
});

const Records = props => {
    let syncIcon;
    switch (props.syncStatus) {
        case SYNCING:
            syncIcon = <SyncIcon fontSize="large" className={props.classes.rotate} />;
            break;
        case SUCCESS:
        default:
            syncIcon = props.hasNotSynced
                ? <SyncIcon fontSize="large" />
                : <img src={props.isAllNotConfirmed ? DisabledSyncSuccessIcon : SyncSuccessIcon} alt="sync_all" />;
            break;
    }

    return (
        <Container>
            {
                props.patient && (
                    <Box mt={4} ml={14}>
                        <IconButton onClick={props.goBack}>
                            <ArrowBackIcon />
                        </IconButton>
                    </Box>
                )
            }
            <Box my={4} textAlign="center">
                <Typography variant="h2" color="secondary">
                    {
                        props.patient
                            ? sprintf(
                                l('History %s %s (%s)'),
                                props.patient.get('firstName'),
                                props.patient.get('lastName'),
                                moment(props.patient.get('dateOfBirth')).format(DATE),
                            ) : l('Records')
                    }
                </Typography>
                <Typography color="secondary">
                    {sprintf(l('Total (%s)'), props.totalItems)}
                </Typography>
            </Box>
            <Box display="flex">
                {
                    props.patient ? (
                        <Fragment>
                            <Button
                                color="primary"
                                variant="contained"
                                className={props.classes.iconButton}
                                startIcon={<img src={FilterIcon} alt="import" />}
                                onClick={props.onClickFilters}
                            >
                                {
                                    props.filtersCount
                                        ? sprintf(l('Filters (%s)'), props.filtersCount)
                                        : l('Filters')
                                }
                            </Button>
                            {
                                !!props.filtersCount && (
                                    <IconButton
                                        color="secondary"
                                        variant="contained"
                                        className={props.classes.clearFiltersButton}
                                        onClick={props.onClickClearFilters}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                )
                            }
                        </Fragment>
                    ) : (
                        <Button
                            color="primary"
                            variant="contained"
                            className={props.classes.iconButton}
                            startIcon={<img src={ImportIcon} alt="import" />}
                            onClick={props.onClickImport}
                        >
                            {l('Import')}
                        </Button>
                    )
                }
                <Box ml={2}>
                    <Button
                        color="primary"
                        variant="text"
                        startIcon={syncIcon}
                        disabled={props.isAllNotConfirmed}
                        className={cx({ [props.classes.opacity]: !props.hasNotSynced })}
                        onClick={props.hasNotSynced ? props.onClickSyncAll : null}
                    >
                        {props.hasNotSynced ? l('Sync All') : l('Synced All')}
                    </Button>
                </Box>
                <Box className={props.classes.filterContainer}>
                    <Button
                        color="primary"
                        variant="contained"
                        className={props.classes.iconButton}
                        startIcon={<img src={FilterIcon} alt="import" />}
                        onClick={props.onClickFilters}
                    >
                        {
                            props.filtersCount
                                ? sprintf(l('Filters (%s)'), props.filtersCount)
                                : l('Filters')
                        }
                    </Button>
                </Box>

                {
                    props.patient && (
                        <Box ml={2}>
                            <Button
                                color="primary"
                                variant="text"
                                startIcon={<img src={ShareIcon} alt="share_icon" />}
                                onClick={props.onClickShare}
                            >
                                {l('Share')}
                            </Button>
                        </Box>
                    )
                }
            </Box>
            <Box height={props.patient ? '60vh' : '70vh'} overflow="auto">
                {props.children}
            </Box>
        </Container>
    );
};

Records.propTypes = {
    patient: ImmutablePropTypes.map,
    hasNotSynced: PropTypes.bool,
    totalItems: PropTypes.number,
    filtersCount: PropTypes.number,
    isAllNotConfirmed: PropTypes.bool,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    children: PropTypes.node.isRequired,
    syncStatus: PropTypes.oneOf([SUCCESS, SYNCING]),
    goBack: PropTypes.func.isRequired,
    onClickShare: PropTypes.func.isRequired,
    onClickImport: PropTypes.func.isRequired,
    onClickFilters: PropTypes.func.isRequired,
    onClickSyncAll: PropTypes.func.isRequired,
    onClickClearFilters: PropTypes.func.isRequired,
};

export default withStyles(styles)(Records);
