import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';

import schemas from 'data/schemas';

import { getEntities } from 'selectors/entities';

export const getDevicesIds = state => state.getIn(['devices', 'ids']);

export const getIsSubmitting = state => state.getIn(['devices', 'submitting']);

export const getPage = state => state.getIn(['devices', 'params', 'page']);

export const getTotalPages = state => state.getIn(['devices', 'totalPages']);

export const hasNext = createSelector(
    getPage,
    getTotalPages,
    (page, totalPages) => !!(totalPages && totalPages >= (page + 1)),
);

export const getDevices = createSelector(
    getEntities,
    getDevicesIds,
    (entities, ids) => denormalize(ids, schemas.DEVICES, entities),
);
