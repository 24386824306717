import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router/immutable';

import { getPathname } from 'selectors/router';
import { getFullName } from 'selectors/session';

import { beginLogout } from 'actions/session';

import Header from 'components/header/Header';

import { PROFILE } from 'data/routes';

const mapStateToProps = state => ({
    fullName: getFullName(state),
    pathName: getPathname(state),
});

const mapDispatchToProps = { beginLogout, push };

const HeaderContainer = props => {
    const handleClickProfile = useCallback(() => {
        props.push(PROFILE);
    }, [props.push]);

    return (
        <Header
            fullName={props.fullName}
            pathName={props.pathName}
            onClickLogout={props.beginLogout}
            onClickProfile={handleClickProfile}
        />
    );
};

HeaderContainer.propTypes = {
    fullName: PropTypes.string.isRequired,
    pathName: PropTypes.string.isRequired,
    push: PropTypes.func.isRequired,
    beginLogout: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(HeaderContainer));
