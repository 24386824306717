import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import HeaderContainer from 'containers/HeaderContainer';
import SpinnerContainer from 'containers/SpinnerContainer';
import RecordsContainer from 'containers/records/RecordsContainer';
import ProfileContainer from 'containers/profiles/ProfileContainer';
import ChangePasswordContainer from 'containers/profiles/ChangePasswordContainer';
import PatientsContainer from 'containers/patients/PatientsContainer';
import NotesContainer from 'containers/notes/NotesContainer';
import AddEditPatientContainer from 'containers/patients/AddEditPatientContainer';
import DevicesContainer from 'containers/devices/DevicesContainer';

import {
    DEVICES,
    PROFILE,
    RECORDS,
    PATIENTS,
    RECORD_NOTES,
    ADD_PATIENT,
    PATIENT_BY_ID,
    CHANGE_PASSWORD,
    HISTORY_RECORDS_BY_PATIENT,
} from 'data/routes';

const styles = () => ({
    wrapperComponent: {
        display: 'block',
        backgroundColor: '#F9F9F9',
        minHeight: '100vh',
    },
});

const MainComponent = props => (
    <Box className={props.classes.wrapperComponent}>
        <SpinnerContainer />
        <HeaderContainer />
        <main>
            <Switch>
                <Route exact path={ADD_PATIENT} component={AddEditPatientContainer} />
                <Route exact path={PATIENT_BY_ID} component={AddEditPatientContainer} />
                <Route exact path={DEVICES} component={DevicesContainer} />
                <Route exact path={PROFILE} component={ProfileContainer} />
                <Route exact path={RECORD_NOTES} component={NotesContainer} />
                <Route exact path={RECORDS} component={RecordsContainer} />
                <Route exact path={HISTORY_RECORDS_BY_PATIENT} component={RecordsContainer} />
                <Route exact path={PATIENTS} component={PatientsContainer} />
                <Route exact path={CHANGE_PASSWORD} component={ChangePasswordContainer} />
                <Redirect to={RECORDS} />
            </Switch>
        </main>
    </Box>
);

MainComponent.propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(MainComponent);
