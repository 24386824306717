import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router/immutable';

import { getFullName } from 'selectors/welcome';

import Welcome from 'components/sign-up/Welcome';

import { LOGIN } from 'data/routes';

const mapStateToProps = state => ({
    fullName: getFullName(state),
});

const mapDispatchToProps = { replace };

const WelcomeContainer = props => {
    const handleClickNext = useCallback(() => {
        props.replace(LOGIN);
    }, [props.replace]);

    return (
        <Welcome fullName={props.fullName} onClickNext={handleClickNext} />
    );
};

WelcomeContainer.propTypes = {
    fullName: PropTypes.string.isRequired,
    replace: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(WelcomeContainer));
