import { isValidPhoneNumber } from 'libphonenumber-js';

import { trimValue } from 'utils/formatterUtils';

/* eslint-disable */
//because eslint means that regex is a mistake
export const PATTERN_ONLY_NUMBERS = /^\d+$/;
export const PATTERN_FIELDS = /^[^<>|#{}~\*\+\$\\\^]*$/;
export const PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
export const PATTERN_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const UUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
/* eslint-enable */

export const checkTextField = value => (
    PATTERN_FIELDS.test(value)
);

export const checkUUID = value => (
    UUID.test(value)
);

export const checkPassword = value => (
    PASSWORD.test(value)
);

export const checkNumbers = value => PATTERN_ONLY_NUMBERS.test(value);

export const checkRegexEmail = value => PATTERN_EMAIL.test(value);

export const email = value => (checkRegexEmail(value) ? null : l('Enter a valid email'));

const isEmptyString = field => !trimValue(field);

export const required = value => (isEmptyString(value) ? l('This field is required') : null);

export const length = (min, max) => value => {
    const trimmedValue = trimValue(value === null ? '' : value);
    return trimmedValue.length > max || trimmedValue.length < min
        ? sprintf(l('Length should be between %d and %d symbols'), min, max)
        : null;
};

export const requiredPassword = value => (isEmptyString(value) ? l('Please enter a password') : null);

export const password = value => (!checkPassword(value) ?
    l('Password must have character, number, uppercase letter and contain at least 6 characters') : null);

export const compareTwoPasswords = (value, values) => {
    if (values.password !== values.confirmPassword) {
        return l('Passwords do not match');
    }
    return null;
};

export const composeValidators = (...validators) => (value, values, meta) => (
    validators.reduce((error, validator) => error || validator(value, values, meta), undefined)
);

export const numbers = value => (value && !checkNumbers(value) ? l('Only numbers are allowed') : null);

export const phone = value => (value && !isValidPhoneNumber(value) ? l('Phone is not correct') : null);

export const specialSymbols = value => (
    checkTextField(value) ? null : l('Field can\'t contain special symbols: <>|#{}~*+$\\^')
);
