import Jed from 'jed';
import moment from 'moment';

import 'moment/locale/en-gb';
import 'moment/locale/sv';

export const DEFAULT_LOCALE = 'en';
export const SUPPORTED_LOCALES = [DEFAULT_LOCALE];

let i18n;
let locale;

export function sprintf(...args) {
    return Jed.sprintf.apply(this, args);
}

export function l(text, context) {
    return context
        ? i18n.pgettext(context, text)
        : i18n.gettext(text);
}

export function nl(singular, plural, amount, context) {
    if (!Number.isInteger(amount)) {
        return singular;
    }
    return context
        ? i18n.npgettext(context, singular, plural, amount)
        : i18n.ngettext(singular, plural, amount);
}

export function init(localeData, localeCode) {
    i18n = new Jed(localeData);
    locale = localeCode;

    window.l = l;
    window.nl = nl;
    window.sprintf = sprintf;
    moment.locale(localeCode);
}

export const getFirstBrowserLanguage = () => {
    const nav = navigator;
    // support for HTML 5.1 "navigator.languages"
    if (nav.languages && nav.languages.length) {
        const found = nav.languages.find(lang => lang && lang.length);
        if (found) {
            return found;
        }
    }

    // support for other well known properties in browsers
    const browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'];
    const foundKey = browserLanguagePropertyKeys.find(key => nav[key] && nav[key].length);
    return nav[foundKey] || null;
};

export function getSupportedLocales() {
    return SUPPORTED_LOCALES;
}

export function getLocale() {
    return locale || DEFAULT_LOCALE;
}

export function isLocaleSupported(localeCode) {
    return getSupportedLocales().indexOf(localeCode) !== -1;
}

export function detectUserLocale() {
    const lang = (localStorage && localStorage.getItem('lang')) || getFirstBrowserLanguage().substr(0, 2);
    if (isLocaleSupported(lang)) {
        return lang;
    }
    return DEFAULT_LOCALE;
}

export function loadLocaleData(localeCode) {
    if (localeCode === DEFAULT_LOCALE) {
        // No need to load as UI already in Default
        return Promise.resolve({});
    }

    return fetch(`/lang/${localeCode}.json`).then(res => {
        if (res.status >= 400) {
            throw new Error('Cannot get locale from server', localeCode);
        }

        return res.json();
    });
}

export function setLocaleData(localeCode) {
    return loadLocaleData(localeCode)
        .catch(err => {
            console.error('error load locale', err);
            return Promise.resolve({});
        })
        .then(localeData => init(localeData, localeCode));
}
