import { useEffect, useState, useRef } from 'react';

const useAudio = url => {
    const audioRef = useRef(null);
    const [playing, setPlaying] = useState(false);

    const toggle = () => setPlaying(!playing);

    useEffect(() => {
        if (playing) {
            if (!audioRef?.current) {
                audioRef.current = new Audio(url);
            }
            audioRef.current?.play();
        } else {
            audioRef.current?.pause();
        }
    }, [url, playing]);

    useEffect(() => {
        audioRef.current?.addEventListener('ended', () => setPlaying(false));
        return () => {
            audioRef.current?.removeEventListener('ended', () => setPlaying(false));
        };
    }, [audioRef.current]);

    return [playing, toggle];
};

export default useAudio;
