import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';

import { Box, Button, Checkbox, FormControlLabel, IconButton, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import CenteredWrapper from 'components/wrappers/CenteredWrapper';

import {
    compareTwoPasswords,
    composeValidators,
    password,
    required,
    email,
    phone,
    length,
    requiredPassword,
} from 'validators/validationInputFields';
import TextField from 'inputs/forms/TextField';

import ShowIcon from 'assets/img/open_eye.svg';
import HideIcon from 'assets/img/close_eye.svg';

import { EMAIL_CONFLICT } from 'data/errors';

import settings from 'settings';

const MIN_MAX_LENGTH_1_50 = length(1, 50);
const MIN_MAX_LENGTH_2_50 = length(2, 50);

const styles = () => ({
    icon: {
        height: 24,
    },
    link: {
        color: 'blue',
    },
});

const SignUp = props => {
    const [isShowPassword, setShowPassword] = useState(false);
    const [isShowConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isAgreeWithTerms, setAgreeWithTerms] = useState(false);

    const handleClickShowPassword = useCallback(() => setShowPassword(prevState => !prevState), []);

    const handleClickShowConfirmPassword = useCallback(() => setShowConfirmPassword(prevState => !prevState), []);

    const handleChangeTerms = useCallback(e => {
        setAgreeWithTerms(e.target.checked);
    }, []);

    return (
        <CenteredWrapper>
            <Box my={2} textAlign="center">
                <Typography variant="h1">
                    {l('Sign up')}
                </Typography>
            </Box>
            <Box mt={4}>
                <Form onSubmit={props.onClickSignUp}>
                    {({ submitError, handleSubmit, submitting }) => (
                        <form onSubmit={handleSubmit}>
                            <Box px={12} mb={2}>
                                <Field
                                    fullWidth
                                    name="firstName"
                                    validate={composeValidators(required, MIN_MAX_LENGTH_2_50)}
                                    placeholder={l('Name')}
                                    component={TextField}
                                />
                            </Box>
                            <Box px={12} mb={2}>
                                <Field
                                    fullWidth
                                    name="lastName"
                                    validate={composeValidators(required, MIN_MAX_LENGTH_1_50)}
                                    placeholder={l('Surname')}
                                    component={TextField}
                                />
                            </Box>
                            <Box px={12} mb={2}>
                                <Field
                                    fullWidth
                                    name="email"
                                    innerError={
                                        submitError === EMAIL_CONFLICT
                                            ? l('An account with the given email already exists.')
                                            : null
                                    }
                                    validate={composeValidators(required, email)}
                                    placeholder={l('Email')}
                                    component={TextField}
                                />
                            </Box>
                            <Box px={12} mb={2}>
                                <Field
                                    fullWidth
                                    name="phone"
                                    validate={composeValidators(required, phone)}
                                    placeholder={l('Phone')}
                                    component={TextField}
                                />
                            </Box>
                            <Box px={12} mb={2}>
                                <Field
                                    fullWidth
                                    name="password"
                                    type={isShowPassword ? 'text' : 'password'}
                                    validate={composeValidators(requiredPassword, password)}
                                    placeholder={l('Password')}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton onClick={handleClickShowPassword}>
                                                <img
                                                    alt="show password"
                                                    className={props.classes.icon}
                                                    src={isShowPassword ? ShowIcon : HideIcon}
                                                />
                                            </IconButton>
                                        ),
                                    }}
                                    component={TextField}
                                />
                            </Box>
                            <Box px={12} mb={2}>
                                <Field
                                    fullWidth
                                    name="confirmPassword"
                                    type={isShowConfirmPassword ? 'text' : 'password'}
                                    validate={compareTwoPasswords}
                                    placeholder={l('Repeat password')}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton onClick={handleClickShowConfirmPassword}>
                                                <img
                                                    alt="show password"
                                                    className={props.classes.icon}
                                                    src={isShowConfirmPassword ? ShowIcon : HideIcon}
                                                />
                                            </IconButton>
                                        ),
                                    }}
                                    component={TextField}
                                />
                            </Box>
                            <Box px={12} mb={2}>
                                <FormControlLabel
                                    label={(
                                        <Typography noWrap>
                                            {l('I agree with')}&nbsp;
                                            <a
                                                className={props.classes.link}
                                                href={settings.policy}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {l('Privacy Policy')}
                                            </a> &&nbsp;
                                            <a
                                                className={props.classes.link}
                                                href={settings.terms}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {l('Terms of Use')}
                                            </a>
                                        </Typography>
                                    )}
                                    control={(
                                        <Checkbox
                                            color="primary"
                                            name="terms"
                                            checked={isAgreeWithTerms}
                                            onChange={handleChangeTerms}
                                        />
                                    )}
                                />
                            </Box>
                            <Box px={12} mt={2}>
                                <Button
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={submitting || !isAgreeWithTerms}
                                >
                                    {l('Sign up')}
                                </Button>
                            </Box>
                            <Box px={12} mt={2}>
                                <Button
                                    fullWidth
                                    color="primary"
                                    disabled={submitting}
                                    onClick={props.onClickSignIn}
                                >
                                    {l('Sign in')}
                                </Button>
                            </Box>
                        </form>
                    )}
                </Form>
            </Box>
        </CenteredWrapper>
    );
};

SignUp.propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onClickSignUp: PropTypes.func.isRequired,
    onClickSignIn: PropTypes.func.isRequired,
};

export default withStyles(styles)(SignUp);
