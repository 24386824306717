import React from 'react';
import { Box, Typography, IconButton, Container } from '@material-ui/core';
import { ArrowBackIos as ArrowBackIosIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import ContactUsWrapper from '../wrappers/ContactUsWrapper';
import ContactUsView from './ContactUsView';

const styles = () => ({
    headerContainer: {
        position: 'relative',
        width: '100%',
        textAlign: 'center',
    },
    buttonContainer: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        left: 0,
    },
    button: { padding: 0 },
});

const ContactUs = props => (
    <ContactUsWrapper>
        <Container>
            <Box className={props.classes.headerContainer}>
                <Box className={props.classes.buttonContainer}>
                    <IconButton onClick={props.onClickLogin} className={props.classes.button} type="submit">
                        <ArrowBackIosIcon />
                    </IconButton>
                </Box>
                <Typography noWrap variant="h3">
                    {l('Contact Us')}
                </Typography>
            </Box>

            <ContactUsView />
        </Container>
    </ContactUsWrapper>
);

ContactUs.propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onClickLogin: PropTypes.func.isRequired,
};

export default withStyles(styles)(ContactUs);
