import { DEVICES, PATIENTS, RECORDS } from 'data/routes';

export const RECORDS_PAGE = 'RECORDS_PAGE';
export const PATIENTS_PAGE = 'PATIENTS_PAGE';
export const DEVICES_PAGE = 'DEVICES_PAGE';

export const getHeaderTabs = () => ([
    {
        key: RECORDS_PAGE,
        path: RECORDS,
        label: l('Records'),
        regExpSelection: /^\/records/,
    },
    {
        key: PATIENTS_PAGE,
        path: PATIENTS,
        label: l('Patients'),
        regExpSelection: /^\/patients/,
    },
    {
        key: DEVICES_PAGE,
        path: DEVICES,
        label: l('Devices'),
        regExpSelection: /^\/devices/,
    },
]);
