import { fromJS } from 'immutable';

import { CLEAR_CONFIRM_DATA, RESEND_CONFIRM_CODE, SET_CONFIRM_DATA } from 'actions/users';
import { failAction, startAction, successAction } from '../actions/actionTypes';

const defaultState = fromJS({
    email: null,
    resending: false,
});

export default function confirmEmail(state = defaultState, action) {
    switch (action.type) {
        case startAction(RESEND_CONFIRM_CODE):
            return state.set('resending', true);
        case failAction(RESEND_CONFIRM_CODE):
        case successAction(RESEND_CONFIRM_CODE):
            return state.set('resending', false);
        case CLEAR_CONFIRM_DATA:
            return defaultState;
        case SET_CONFIRM_DATA:
            return state.merge(fromJS(action.payload));
        default:
            return state;
    }
}
