export const HEART = 'heart';
export const LUNG = 'lung';

export const getOrgans = () => ({
    [HEART]: l('Heart'),
    [LUNG]: l('Lung'),
});

export const getOrganOptions = () => ([
    { name: l('Heart'), value: HEART },
    { name: l('Lung'), value: LUNG },
]);
