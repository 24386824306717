import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { withStyles } from '@material-ui/core/styles';

import filterToQuery from 'hoc/filterToQuery';

import InputComponent from 'inputs/InputComponent';

const styles = theme => ({
    container: {
        marginTop: theme.spacing(),
        marginBottom: theme.spacing(),
    },
    formControl: {
        marginRight: theme.spacing(),
    },
    paper: {
        paddingBottom: 0,
    },
});

let timer = null;

function FilterSearchInput(props) {
    const [value, setValue] = useState(props.query.get(props.fieldName));

    useEffect(() => {
        if (!props.query.get(props.fieldName)) {
            setValue('');
        }
    }, [props.query]);

    const handleChange = useCallback(e => {
        e.persist();
        clearTimeout(timer);
        setValue(e.target.value);
        timer = setTimeout(() => props.onChangeFilterWithReplace({ name: props.fieldName, value: e.target.value }),
            500);
    });

    const handleClear = useCallback(() => {
        setValue('');
        if (props.query.get(props.fieldName)) {
            props.onChangeFilterWithReplace({ name: props.fieldName, value: '' });
        }
    }, [props.fieldName, props.query]);

    const customProps = {
        InputProps: {
            disableUnderline: true,
            startAdornment: props.adornment || null,
        },
        InputLabelProps: { shrink: true },
    };

    return (
        <div className={`${props.classes.container} ${props.className}`}>
            <InputComponent
                id={props.id}
                clearable={props.clearable}
                label={props.label}
                value={value || ''}
                inputPadding={props.inputPadding}
                classes={{ root: props.classes.paper }}
                placeholder={props.placeholder}
                onChange={handleChange}
                onClear={handleClear}
                {...customProps}
            />
        </div>
    );
}

FilterSearchInput.propTypes = {
    clearable: PropTypes.bool,
    label: PropTypes.string,
    id: PropTypes.string,
    fieldName: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    inputPadding: PropTypes.string,
    adornment: PropTypes.node,
    query: ImmutablePropTypes.map,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onChangeFilterWithReplace: PropTypes.func.isRequired,
};

export default filterToQuery(withStyles(styles)(FilterSearchInput));
