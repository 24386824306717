import { ERROR } from 'data/messages';

import { getRandomInt } from 'utils/generator';

export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';

export const showMessage = (message, type = ERROR) => ({
    type: SHOW_MESSAGE,
    id: getRandomInt(),
    messageType: type,
    message,
});

export const hideMessage = id => ({
    type: HIDE_MESSAGE,
    id,
});
