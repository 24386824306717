export const UNAUTHORIZED = 'UNAUTHORIZED';
export const RESPONSE_ERROR = 'RESPONSE_ERROR';
export const SEND_REQUEST = 'SEND_REQUEST';

export const startAction = type => `START_${type}`;
export const successAction = type => `SUCCESS_${type}`;
export const failAction = type => `FAIL_${type}`;

export const startSseSubscribe = type => `START_SSE_${type}`;
export const receiveSseMessage = type => `RECEIVE_SSE_MESSAGE_${type}`;
export const onSubscribeSse = type => `SUBSCRIBE_SSE_${type}`;
export const onUnsubscribeSse = type => `UNSUBSCRIBE_SSE_${type}`;
