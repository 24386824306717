import { fromJS, List } from 'immutable';

import { HIDE_MESSAGE, SHOW_MESSAGE } from 'actions/messages';

import { ERROR } from 'data/messages';

const defaultState = fromJS({
    splashMessages: [],
});

const messages = (state = defaultState, action) => {
    switch (action.type) {
        case SHOW_MESSAGE: {
            const splashMessages = action.messageType === ERROR
                ? state.get('splashMessages').filterNot(item => item.get('type') === ERROR)
                : state.get('splashMessages');
            const newMessages = splashMessages.unshift(fromJS({
                id: action.id,
                message: action.message,
                type: action.messageType,
            }));
            return state.set('splashMessages', newMessages);
        }
        case HIDE_MESSAGE:
            return state.update('splashMessages', (list = List()) => (
                list.filterNot(item => item.get('id') === action.id)
            ));
        default:
            return state;
    }
};

export default messages;
