import { memo } from 'react';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router/immutable';

import { changePassword } from 'actions/users';

import ChangePassword from 'components/profiles/ChangePassword';

const mapDispatchToProps = { onClickSave: changePassword, onClickCancel: goBack };

export default connect(null, mapDispatchToProps)(memo(ChangePassword));
