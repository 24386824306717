import { memo } from 'react';
import { connect } from 'react-redux';

import Spinner from 'components/Spinner';

import { isShowSpinner } from 'selectors/spinner';

const mapStateToProps = state => ({
    isShowSpinner: isShowSpinner(state),
});

export default connect(mapStateToProps)(memo(Spinner));
