import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';

import { getEmail } from 'selectors/confirmEmail';
import { getIsLoggedIn, getIsLoggingIn } from 'selectors/session';

import { LOGIN, MAIN_PATH } from 'data/routes';

const locationHelper = locationHelperBuilder({});

export const userIsAuthenticatedRedir = connectedRouterRedirect({
    authenticatedSelector: getIsLoggedIn,
    authenticatingSelector: getIsLoggingIn,
    redirectPath: LOGIN,
});

export const userIsNotAuthenticatedRedir = connectedRouterRedirect({
    authenticatedSelector: state => !getIsLoggedIn(state),
    redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || MAIN_PATH,
    allowRedirectBack: false,
});

export const userIsShouldConfirmEmail = connectedRouterRedirect({
    authenticatedSelector: state => !getIsLoggedIn(state) && !!getEmail(state),
    redirectPath: () => LOGIN,
    allowRedirectBack: false,
});
