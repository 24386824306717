export const A_T = 'A-T';
export const A_URLF = 'A-URLF';
export const A_ULLF = 'A-ULLF';
export const A_MLLF = 'A-MLLF';
export const A_MRLF = 'A-MRLF';
export const A_LRLF = 'A-LRLF';
export const A_LLLF = 'A-LLLF';
export const P_ULLF = 'P-ULLF';
export const P_URLF = 'P-URLF';
export const P_MRLF = 'P-MRLF';
export const P_MLLF = 'P-MLLF';
export const P_LLLF = 'P-LLLF';
export const P_LRLF = 'P-LRLF';
export const P_RCA = 'P-RCA';
export const P_LCA = 'P-LCA';
export const AORTIC = 'aortic';
export const ERBS_POINT = 'erbs_point';
export const TRICUSPID = 'tricuspid';
export const MITRAL = 'mitral';
export const PULMONIC = 'pulmonic';

export const getAuscultationAreaLungOptions = () => ([
    { name: l('Anterior - trachea'), value: A_T },
    { name: l('Anterior - upper right lung field'), value: A_URLF },
    { name: l('Anterior - upper left lung field '), value: A_ULLF },
    { name: l('Anterior - middle left lung field'), value: A_MLLF },
    { name: l('Anterior - middle right lung field '), value: A_MRLF },
    { name: l('Anterior - lower right lung field'), value: A_LRLF },
    { name: l('Anterior - lower left lung field '), value: A_LLLF },
    { name: l('Posterior - upper left lung field '), value: P_ULLF },
    { name: l('Posterior - upper right lung field '), value: P_URLF },
    { name: l('Posterior - middle right lung field'), value: P_MRLF },
    { name: l('Posterior - middle left lung field '), value: P_MLLF },
    { name: l('Posterior - lower left lung field '), value: P_LLLF },
    { name: l('Posterior - lower right lung field '), value: P_LRLF },
    { name: l('Posterior - right costophrenic angle'), value: P_RCA },
    { name: l('Posterior - left costophrenic angle '), value: P_LCA },
]);
export const getAuscultationAreaHeartOptions = () => ([
    { name: l('Aortic'), value: AORTIC },
    { name: l('Erbs Point'), value: ERBS_POINT },
    { name: l('Tricuspid'), value: TRICUSPID },
    { name: l('Mitral'), value: MITRAL },
    { name: l('Pulmonic'), value: PULMONIC },
]);
