const settings = {
    tableEntitiesLimit: 10,
    timeoutRequests: 30000,
    delayMessage: 5000,
    acceptedFiles: 'audio/wave,audio/wav,audio/x-wav,audio/x-pn-wav',
    maxFileSize: 2 * 1024 * 1024,
    terms: 'https://smart-static-docs.s3.us-west-2.amazonaws.com/Terms+%26+Conditions+-++Smart.pdf',
    policy: 'https://smart-static-docs.s3.us-west-2.amazonaws.com/Privacy+Policy+-+Smart.pdf',
};

export default settings;
