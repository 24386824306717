import axios from 'axios';
import { FORM_ERROR } from 'final-form';

import {
    failAction,
    RESPONSE_ERROR,
    SEND_REQUEST,
    startAction,
    successAction,
    UNAUTHORIZED,
} from 'actions/actionTypes';

import { getAccessToken } from 'selectors/session';

import { getLocale } from 'i18n';

import settings from 'settings';

const instance = axios.create({
    timeout: settings.timeoutRequests,
    withCredentials: true,
});

export const callApi = (headers, method = 'get', endpoint, body, params, responseType = 'json') => instance({
    headers, url: endpoint, method, data: body, params, responseType,
});

export const CALL_API = Symbol('CALL_API');

export default store => next => action => {
    const callAPI = action[CALL_API];

    if (typeof callAPI === 'undefined') {
        return next(action);
    }

    let { endpoint, headers = {} } = callAPI;
    const {
        type, method = 'get', body = {}, params, converter = response => response,
        responseType, submitFormRequest, payload,
    } = callAPI;

    if (typeof endpoint === 'function') {
        endpoint = endpoint(store.getState());
    }

    if (typeof endpoint !== 'string') {
        throw new Error('Specify a string endpoint URL.');
    }

    if (typeof type !== 'string') {
        throw new Error('Expected action type to be string.');
    }

    const actionWith = data => {
        const finalAction = {
            ...action,
            ...data,
        };

        delete finalAction[CALL_API];

        return finalAction;
    };
    store.dispatch(actionWith({
        type: startAction(type),
    }));
    store.dispatch({ type: SEND_REQUEST, requestType: type });
    headers = {
        ...headers,
        Authorization: `Bearer ${getAccessToken(store.getState())}`,
        locale: getLocale(),
    };

    return callApi(headers, method, endpoint, body, params, responseType).then(
        response => store.dispatch(actionWith({
            response: converter(response),
            type: successAction(type),
            payload,
        })),
        error => {
            if (!error.response) {
                return store.dispatch(actionWith({
                    type: failAction(type),
                    error: error.message || 'Error happened during API call',
                }));
            }
            if (error.response.status) {
                store.dispatch(actionWith({
                    type: RESPONSE_ERROR,
                    response: error.response,
                    submitFormRequest,
                }));
            }
            if (error.response.status === 401) {
                return store.dispatch(actionWith({
                    type: UNAUTHORIZED,
                    payload: {
                        ...action,
                        [CALL_API]: {
                            ...callAPI,
                        },
                    },
                }));
            }
            const dispatchPromise = store.dispatch(actionWith({
                type: failAction(type),
                response: error.response,
                error: (error.response && error.response.data) || 'Error happened during API call',
            }));
            if (submitFormRequest) {
                console.log('error', error.response);
                return { [FORM_ERROR]: error.response.data.message };
            }
            return dispatchPromise;
        },
    );
};
