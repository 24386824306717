import { normalize } from 'normalizr';

export const normalizePagingResponse = schema => response => {
    const res = { ...response };
    res.normalizedData = normalize(res.data.data || res.data.items, schema);
    return res;
};

export const normalizeEntityResponse = schema => response => {
    const res = { ...response };
    res.normalizedData = normalize(res.data, schema);
    return res;
};

export const dataURItoBlob = dataURI => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    const ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    return new Blob([ab], { type: mimeString });
};

export const convertQueryParams = params => (
    Object.keys(params).reduce((result, key) => {
        const value = params[key];
        if (Array.isArray(value)) {
            value.forEach(el => result.append(key, el));
        } else {
            result.append(key, value);
        }
        return result;
    }, new URLSearchParams())
);
