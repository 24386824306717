import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router/immutable';

import { userIsAuthenticatedRedir, userIsNotAuthenticatedRedir, userIsShouldConfirmEmail } from 'auth';

import MainComponent from 'components/MainComponent';
import LoginContainer from 'containers/LoginContainer';
import ResetPasswordContainer from 'containers/ResetPasswordContainer';
import SignUpContainer from 'containers/SignUpContainer';
import ConfirmEmailContainer from 'containers/ConfirmEmailContainer';
import WelcomeContainer from 'containers/WelcomeContainer';
import ContactUsContainer from 'containers/ContactUsContainer';
import SnackbarMessagesContainer from 'containers/messages/SnackbarMessagesContainer';

import { CONFIRM, CONTACT_US, LOGIN, MAIN_PATH, RESET_PASSWORD, SIGN_UP, WELCOME } from 'data/routes';

const Login = userIsNotAuthenticatedRedir(LoginContainer);
const ResetPassword = userIsNotAuthenticatedRedir(ResetPasswordContainer);
const SignUp = userIsNotAuthenticatedRedir(SignUpContainer);
const Welcome = userIsNotAuthenticatedRedir(WelcomeContainer);
const ConfirmEmail = userIsShouldConfirmEmail(ConfirmEmailContainer);
const ContactUs = userIsNotAuthenticatedRedir(ContactUsContainer);
const Main = userIsAuthenticatedRedir(MainComponent);

const App = ({ history }) => (
    <ConnectedRouter history={history}>
        <SnackbarMessagesContainer />
        <Switch>
            <Route exact path={LOGIN} component={Login} />
            <Route exact path={RESET_PASSWORD} component={ResetPassword} />
            <Route exact path={SIGN_UP} component={SignUp} />
            <Route exact path={CONFIRM} component={ConfirmEmail} />
            <Route exact path={WELCOME} component={Welcome} />
            <Route exact path={CONTACT_US} component={ContactUs} />
            <Route path={MAIN_PATH} component={Main} />
        </Switch>
    </ConnectedRouter>
);

App.propTypes = {
    history: PropTypes.object, // eslint-disable-line
};

export default App;
