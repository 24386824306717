import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { contactEmail } from '../../data/contacts';

const styles = () => ({
    textContainer: {
        maxWidth: 340,
        margin: '24px auto 0 auto',
    },
    text: {
        color: '#3B3B3B',
        fontSize: 18,
    },
    email: {
        width: '100%',
        color: '#51B3BD',
        fontSize: 18,
        fontWeight: 'bold',
        textDecoration: 'none',
    },
});

const ContactUsView = props => (
    <Box className={props.classes.textContainer}>
        <Typography variant="subtitle1">
            {l(`If you have any questions or suggestions about our App,
            do not hesitate to contact us at: `)}
        </Typography>

        <Box textAlign="center" mt={1}>
            <Typography
                variant="subtitle1"
                component="a"
                href={`mailto:${contactEmail}`}
                className={props.classes.email}
            >
                {contactEmail}
            </Typography>
        </Box>
    </Box>
);

ContactUsView.propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(ContactUsView);
