export const getIsLoggedIn = state => state.getIn(['session', 'isLoggedIn']);

export const getIsLoggingIn = state => state.getIn(['session', 'isLoggingIn']);

export const getAppToken = state => state.getIn(['session', 'appToken']);

// eslint-disable-next-line max-len
export const getFullName = state => `${state.getIn(['session', 'appToken', 'user', 'firstName'])} ${state.getIn(['session', 'appToken', 'user', 'lastName'])}`;

export const getAccessToken = state => state.getIn(['session', 'appToken', 'accessToken']);

export const getUser = state => state.getIn(['session', 'appToken', 'user']);

export const getUserId = state => state.getIn(['session', 'appToken', 'user', 'id']);
