import React, { Fragment, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { Box, Button, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import PromptConfirmationPopup from 'components/popups/PromptConfirmationDialog';

import TextField from 'inputs/forms/TextField';

import { composeValidators, length, phone, required } from 'validators/validationInputFields';

import LockIcon from 'assets/img/lock.svg';

const MIN_MAX_LENGTH_1_50 = length(1, 50);
const MIN_MAX_LENGTH_2_50 = length(2, 50);

const styles = () => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
    },
    paper: {
        width: 600,
        minHeight: 350,
        padding: '20px 10px',
        boxShadow: '0 2px 8px 0 rgba(0,0,0,0.14)',
        borderRadius: 2,
    },
});

const Profile = props => {
    useEffect(() => () => props.onChangePristineForm({ pristine: true }), []);

    useEffect(() => {
        props.onChangePristineForm({ pristine: props.pristine });
    }, [props.pristine]);

    const handleLeave = useCallback(location => {
        if (!props.pristine) {
            props.showPromptPopup(location);
            return false;
        }
        return true;
    }, [props.pristine]);

    return (
        <Fragment>
            <PromptConfirmationPopup
                text={l('Are you sure you want to leave the screen without saving?')}
                location={props.promptLocation}
                isOpenPopup={props.isOpenPromptConfirmPopup}
                onClickOk={props.onClickLeave}
                onClickCancel={props.onClickStay}
                onLeave={handleLeave}
            />
            <Box className={props.classes.container}>
                <Box my={7} display="flex">
                    <Typography variant="h2" color="secondary">
                        {sprintf(
                            l('%s %s Profile (yours)'),
                            props.initialValues.firstName,
                            props.initialValues.lastName,
                        )}
                    </Typography>
                    <Box position="absolute" right="calc(5% + 24px)">
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<img src={LockIcon} alt="lock" />}
                            onClick={props.onClickChangePassword}
                        >
                            {l('Change password')}
                        </Button>
                    </Box>
                </Box>
                <Paper className={props.classes.paper}>
                    <Box my={2} textAlign="center">
                        <Typography variant="h3">
                            {l('Clinician’s info')}
                        </Typography>
                    </Box>
                    <Box mt={4}>
                        <form onSubmit={props.handleSubmit}>
                            <Box px={12} mb={2}>
                                <Field
                                    fullWidth
                                    name="firstName"
                                    validate={composeValidators(required, MIN_MAX_LENGTH_2_50)}
                                    placeholder={l('Name')}
                                    component={TextField}
                                />
                            </Box>
                            <Box px={12} mb={2}>
                                <Field
                                    fullWidth
                                    name="lastName"
                                    validate={composeValidators(required, MIN_MAX_LENGTH_1_50)}
                                    placeholder={l('Surname')}
                                    component={TextField}
                                />
                            </Box>
                            <Box px={12} mb={2}>
                                <Field
                                    fullWidth
                                    name="phone"
                                    validate={composeValidators(required, phone)}
                                    placeholder={l('Phone')}
                                    component={TextField}
                                />
                            </Box>
                            <Box px={12} mb={2}>
                                <Field
                                    fullWidth
                                    disabled
                                    name="email"
                                    placeholder={l('Email')}
                                    component={TextField}
                                />
                            </Box>
                            <Box px={12} mt={2}>
                                <Button
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={props.submitting || props.pristine}
                                >
                                    {l('Save')}
                                </Button>
                            </Box>
                        </form>
                    </Box>
                </Paper>
            </Box>
        </Fragment>
    );
};

Profile.propTypes = {
    promptLocation: ImmutablePropTypes.map,
    initialValues: PropTypes.shape({
        email: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        phone: PropTypes.string.isRequired,
    }).isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    isOpenPromptConfirmPopup: PropTypes.bool.isRequired,
    onClickStay: PropTypes.func.isRequired,
    onClickLeave: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    showPromptPopup: PropTypes.func.isRequired,
    onChangePristineForm: PropTypes.func.isRequired,
    onClickChangePassword: PropTypes.func.isRequired,
};

export default withStyles(styles)(Profile);
