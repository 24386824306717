import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router/immutable';

import { login } from 'actions/session';

import Login from 'components/Login';

import { CONTACT_US, SIGN_UP } from 'data/routes';

const mapDispatchToProps = { login, replace };

const LoginContainer = props => {
    const handleClickSignUp = useCallback(() => props.replace(SIGN_UP), [props.replace]);
    const handleContactUs = useCallback(() => props.replace(CONTACT_US), [props.replace]);

    return (
        <Login
            onClickLogin={props.login}
            onClickSignUp={handleClickSignUp}
            onClickContactUs={handleContactUs}
        />
    );
};

LoginContainer.propTypes = {
    login: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(memo(LoginContainer));
