import { fromJS } from 'immutable';

import { successAction } from 'actions/actionTypes';
import { SIGN_UP } from 'actions/users';

const defaultState = fromJS({ firstName: '', lastName: '' });

export default function welcome(state = defaultState, action) {
    switch (action.type) {
        case successAction(SIGN_UP): {
            return fromJS({
                firstName: action.firstName,
                lastName: action.lastName,
            });
        }
        default:
            return state;
    }
}
