import React from 'react';
import PropTypes from 'prop-types';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
    dialog: {
        zIndex: '999999!important;',
    },
    description: {
        color: '#000',
        fontSize: '16px',
        fontWeight: 'normal',
        textTransform: 'none',
    },
});

const ConfirmationDialog = props => (
    <Dialog
        open={props.isOpened}
        className={props.classes.dialog}
        maxWidth={props.maxWidth}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={props.onClickCancel}
    >
        {props.title && (
            <DialogTitle id="alert-dialog-title" disableTypography>
                {props.title}
            </DialogTitle>
        )}
        <DialogContent dividers={props.dividers} ref={props.contentRef}>
            <DialogContentText
                id="alert-dialog-description"
                component="div"
                className={props.classes.description}
            >
                {props.text || props.children}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            {
                props.onClickCancel && (
                    <Button
                        fullWidth
                        variant="text"
                        color="primary"
                        onClick={props.onClickCancel}
                    >
                        {props.cancelText || l('Cancel')}
                    </Button>
                )
            }
            {
                props.onClickOk && (
                    <Button
                        fullWidth
                        variant="text"
                        color="primary"
                        disabled={props.disabledOk}
                        onClick={props.onClickOk}
                    >
                        {props.okText || l('OK')}
                    </Button>
                )
            }
        </DialogActions>
    </Dialog>
);

ConfirmationDialog.propTypes = {
    text: PropTypes.node,
    disabledOk: PropTypes.bool,
    okText: PropTypes.node,
    cancelText: PropTypes.node,
    maxWidth: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
    ]),
    title: PropTypes.string,
    children: PropTypes.node,
    isOpened: PropTypes.bool.isRequired,
    dividers: PropTypes.bool,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    contentRef: PropTypes.object, // eslint-disable-line
    onClickOk: PropTypes.func,
    onClickCancel: PropTypes.func,
};

ConfirmationDialog.defaultProps = {
    maxWidth: 'xs',
    dividers: false,
};

export default withStyles(styles)(ConfirmationDialog);
