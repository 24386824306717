import { CALL_API } from 'middleware/api';

export const SEND_EMAIL_RESET_PASSWORD = 'SEND_EMAIL_RESET_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';

export const sendEmailResetPassword = body => dispatch => (
    dispatch({
        email: body.email,
        [CALL_API]: {
            type: SEND_EMAIL_RESET_PASSWORD,
            endpoint: '/api/v1/auth/password/forgot',
            body,
            method: 'post',
            submitFormRequest: true,
        },
    })
);

export const sendNewPassword = body => dispatch => (
    dispatch({
        password: body.password,
        email: body.email,
        [CALL_API]: {
            type: RESET_PASSWORD,
            endpoint: '/api/v1/auth/password/confirm',
            body,
            method: 'post',
            submitFormRequest: true,
        },
    })
);
