export const SHOW_SPINNER = 'SHOW_SPINNER';
export const HIDE_SPINNER = 'HIDE_SPINNER';

export const showSpinner = {
    type: SHOW_SPINNER,
};

export const hideSpinner = {
    type: HIDE_SPINNER,
};
