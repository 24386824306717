import { put, takeLatest } from 'redux-saga/effects';

import { showMessage } from 'actions/messages';
import { RESET_PASSWORD } from 'actions/resetPassword';
import { ADD_PATIENT, DELETE_PATIENT, SHARE_PATIENT } from 'actions/patients';
import { CHANGE_PASSWORD, UPDATE_PROFILE } from 'actions/users';
import { UPDATE_DEVICE } from 'actions/devices';
import { RESPONSE_ERROR, successAction } from 'actions/actionTypes';

import { SUCCESS, WARNING } from 'data/messages';
import { RESPONSE_STATUSES } from '../data/httpStatuses';

const listOfCodesToDisplayErrorMessages = [
    RESPONSE_STATUSES.NOT_FOUND,
    RESPONSE_STATUSES.BAD_REQUEST,
    RESPONSE_STATUSES.CONFLICT,
];

function* showErrorMessage(action) {
    if (action.response.status >= RESPONSE_STATUSES.INTERNAL_SERVER_ERROR) {
        yield put(showMessage(l('Something went wrong.')));
    } else if (listOfCodesToDisplayErrorMessages.includes(action.response.status)) {
        yield put(showMessage(action.response.data.message || action.response.statusText, WARNING));
    }
}

function* showResponseMessage(action) {
    let message = null;
    switch (action.type) {
        case successAction(CHANGE_PASSWORD):
            message = l('The password was changed');
            break;
        case successAction(UPDATE_PROFILE):
            message = l('Your profile has been updated successfully!');
            break;
        case successAction(UPDATE_DEVICE):
            message = l('Your device has been updated successfully!');
            break;
        case successAction(RESET_PASSWORD):
            message = l('Password has been reset');
            break;
        case successAction(DELETE_PATIENT):
            message = l('Patient has been deleted');
            break;
        case successAction(ADD_PATIENT):
            message = l('Patient has been created');
            break;
        case successAction(SHARE_PATIENT):
            message = l('Patient has been shared');
            break;
        default:
            break;
    }
    if (message) {
        yield put(showMessage(message, SUCCESS));
    }
}

export function* watchErrors() {
    yield takeLatest(RESPONSE_ERROR, showErrorMessage);
}

export function* watchSuccess() {
    yield takeLatest(action => action.type.includes(successAction('')), showResponseMessage);
}
