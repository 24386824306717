import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { push, goBack } from 'connected-react-router/immutable';
import { Form } from 'react-final-form';

import { changePristineForm } from 'actions/finalForms';
import { resetPromptPopup, showPromptPopup } from 'actions/prompt';
import { addPatient, updatePatient, readPatient, deletePatient } from 'actions/patients';

import { getLocation } from 'selectors/router';
import { getPatientById } from 'selectors/patients';
import { getPromptLocation, isOpen } from 'selectors/prompt';
import { getUserId } from 'selectors/session';

import Patient from 'components/patients/Patient';
import ConfirmationDialog from 'components/popups/ConfirmationDialog';

import { MALE } from 'data/sex';
import { USA } from 'data/countries';
import { STAY } from 'data/prompt';

const mapStateToProps = (state, ownProps) => {
    const patientId = ownProps.match && ownProps.match.params.patientId;
    return ({
        patientId,
        userId: getUserId(state),
        patient: getPatientById(state)(patientId),
        isOpenPromptConfirmPopup: isOpen(state),
        promptLocation: getPromptLocation(state),
        location: getLocation(state),
    });
};

const mapDispatchToProps = {
    push,
    goBack,
    addPatient,
    readPatient,
    updatePatient,
    deletePatient,
    showPromptPopup,
    resetPromptPopup,
    changePristineForm,
};

const DEFAULT_VALUES = { gender: MALE, country: USA };

const AddEditPatientContainer = props => {
    const [isOpenDeletingConfirmation, setIsOpenDeletingConfirmation] = useState(false);
    const [isShared, setIsShared] = useState(false);

    useEffect(() => {
        if (props.patient && props.userId && props.userId !== props.patient.get('createdBy')) {
            setIsShared(true);
        }
    }, [props.userId, props.patient]);

    useEffect(() => {
        if (props.patientId) {
            props.readPatient(props.patientId);
        }
    }, [props.patientId]);

    const handleClickLeave = useCallback(() => {
        props.resetPromptPopup();
        if (props.promptLocation.get('key') !== props.location.get('key')) {
            props.push(props.promptLocation.toJS());
        }
    }, [props.promptLocation, props.location, props.resetPromptPopup, props.push]);

    const handleClickSave = useCallback(values => {
        const saveFunc = props.patientId ? props.updatePatient : props.addPatient;
        return saveFunc(values);
    }, [props.patientId, props.addPatient, props.updatePatient]);

    const handleClickDelete = useCallback(() => {
        setIsOpenDeletingConfirmation(true);
    }, [props.patientId, props.addPatient, props.updatePatient]);

    const handleClickCancel = useCallback(() => {
        setIsOpenDeletingConfirmation(false);
    }, []);

    const handleClickConfirm = useCallback(() => {
        props.deletePatient(props.patientId);
    }, [props.patientId, props.deletePatient]);

    const handleClickStay = useCallback(() => {
        props.resetPromptPopup(STAY);
    }, [props.resetPromptPopup]);

    return (
        <Fragment>
            {
                isOpenDeletingConfirmation && (
                    <ConfirmationDialog
                        isOpened
                        onClickOk={handleClickConfirm}
                        onClickCancel={handleClickCancel}
                    >
                        {l('Are you sure you want to delete this patient’s profile? ' +
                            'All data and records will be removed.')}
                    </ConfirmationDialog>
                )
            }
            <Form
                component={Patient}
                initialValues={props.patient ? props.patient.toJS() : DEFAULT_VALUES}
                isShared={isShared}
                isOpenPromptConfirmPopup={props.isOpenPromptConfirmPopup}
                onSubmit={handleClickSave}
                onSuccessSubmit={props.goBack}
                onClickPatientLink={props.goBack}
                promptLocation={props.promptLocation}
                showPromptPopup={props.showPromptPopup}
                onClickStay={handleClickStay}
                onClickLeave={handleClickLeave}
                onClickDelete={handleClickDelete}
                onChangePristineForm={props.changePristineForm}
            />
        </Fragment>
    );
};
AddEditPatientContainer.propTypes = {
    patientId: PropTypes.string,
    userId: PropTypes.string,
    patient: ImmutablePropTypes.map,
    location: ImmutablePropTypes.map,
    promptLocation: ImmutablePropTypes.map,
    isOpenPromptConfirmPopup: PropTypes.bool,
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    addPatient: PropTypes.func.isRequired,
    readPatient: PropTypes.func.isRequired,
    deletePatient: PropTypes.func.isRequired,
    updatePatient: PropTypes.func.isRequired,
    showPromptPopup: PropTypes.func.isRequired,
    resetPromptPopup: PropTypes.func.isRequired,
    changePristineForm: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(AddEditPatientContainer));
