import { denormalize } from 'normalizr';
import { createSelector, defaultMemoize } from 'reselect';

import schemas from 'data/schemas';

import { getEntities } from 'selectors/entities';

export const getPatientsIds = state => state.getIn(['patients', 'ids']);

export const getIsSubmitting = state => state.getIn(['patients', 'submitting']);

export const getPage = state => state.getIn(['patients', 'params', 'page']);

export const getTotalPages = state => state.getIn(['patients', 'totalPages']);

export const getActivePatient = state => state.getIn(['patients', 'activePatient']);

export const hasNext = createSelector(
    getPage,
    getTotalPages,
    (page, totalPages) => !!(totalPages && totalPages >= (page + 1)),
);

export const getPatients = createSelector(
    getEntities,
    getPatientsIds,
    (entities, ids) => denormalize(ids, schemas.PATIENTS, entities),
);

export const getPatientById = createSelector(
    getEntities,
    entities => defaultMemoize(id => denormalize(id, schemas.PATIENT, entities)),
);
