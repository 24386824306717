import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';

import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import CenteredWrapper from 'components/wrappers/CenteredWrapper';

import { checkNumbers, required, numbers, composeValidators } from 'validators/validationInputFields';

import TextField from 'inputs/forms/TextField';

const styles = () => ({
    link: {
        color: '#51B3BD',
        textDecoration: 'none',
    },
    icon: {
        height: 12,
    },
    send: {
        marginLeft: 8,
        color: '#51B3BD',
        cursor: 'pointer',
    },
});

const ConfirmEmail = props => {
    const handleKeyPress = useCallback(event => {
        if (!checkNumbers(event.key)) {
            event.preventDefault();
        }
    }, []);

    return (
        <CenteredWrapper>
            <Box my={2} textAlign="center">
                <Typography variant="h1">
                    {l('Сonfirm registration')}
                </Typography>
            </Box>
            <Box px={12} mb={2}>
                <Typography>
                    {l('We sent you a code to confirm registration')}
                </Typography>
                <Typography>
                    {sprintf(l('Check your email %s'), props.email)}
                </Typography>
            </Box>
            <Box mt={4}>
                <Form onSubmit={props.onClickConfirm}>
                    {({ submitError, submitting, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <Box px={12} mb={4}>
                                <Field
                                    fullWidth
                                    name="code"
                                    innerError={submitError ? l('Incorrect code. Please check and try again.') : null}
                                    validate={composeValidators(required, numbers)}
                                    placeholder={l('Enter your code')}
                                    component={TextField}
                                    onKeyPress={handleKeyPress}
                                />
                                <Box display="flex" justifyContent="flex-end">
                                    {
                                        props.resending ? (
                                            <Box mr={2}>
                                                <CircularProgress color="primary" size={18} />
                                            </Box>
                                        ) : (
                                            <Fragment>
                                                <Typography variant="body2">
                                                    {l('Code missing?')}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    className={props.classes.send}
                                                    onClick={props.onClickResendConfirmation}
                                                >
                                                    {l('Send again')}
                                                </Typography>
                                            </Fragment>
                                        )
                                    }
                                </Box>
                            </Box>
                            <Box px={12} mt={4}>
                                <Button
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={submitting}
                                >
                                    {l('Confirm')}
                                </Button>
                            </Box>
                            <Box px={12} mt={2}>
                                <Button
                                    fullWidth
                                    color="primary"
                                    disabled={submitting}
                                    onClick={props.onClickCancel}
                                >
                                    {l('Cancel')}
                                </Button>
                            </Box>
                        </form>
                    )}
                </Form>
            </Box>
        </CenteredWrapper>
    );
};

ConfirmEmail.propTypes = {
    resending: PropTypes.bool,
    email: PropTypes.string.isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onClickCancel: PropTypes.func.isRequired,
    onClickConfirm: PropTypes.func.isRequired,
    onClickResendConfirmation: PropTypes.func.isRequired,
};

export default withStyles(styles)(ConfirmEmail);
