export const MAIN_COLOR = '#51B3BD';

export default {
    typography: {
        fontFamily: 'Nunito',
    },
    overrides: {
        MuiTypography: {
            h1: {
                fontSize: 32,
            },
            h2: {
                fontSize: 28,
            },
            h3: {
                fontSize: 24,
            },
            body1: {
                fontSize: 18,
            },
            body2: {
                fontSize: 16,
            },
            caption: {
                fontSize: 14,
            },
            colorSecondary: {
                color: '#919699',
            },
        },
        MuiTableCell: {
            root: {
                borderBottom: 'unset',
            },
            head: {
                fontSize: 16,
                color: '#919699',
                borderBottom: '1px solid rgba(224, 224, 224, 1)',
            },
            body: {
                borderBottom: 'unset',
                padding: '0 16px',
            },
            stickyHeader: {
                backgroundColor: '#fff',
            },
        },
        MuiTooltip: {
            tooltip: {
                fontSize: 16,
            },
        },
        MuiFormLabel: {
            root: {
                top: '-3px',
                '&$focused': {
                    color: 'inherit',
                },
            },
        },
        MuiDialogTitle: {
            root: {
                margin: 'auto',
            },
        },
        MuiRadio: {
            colorPrimary: {
                '&$checked': {
                    color: MAIN_COLOR,
                },
            },
        },
        MuiDialogActions: {
            root: {
                justifyContent: 'space-around',
            },
        },
        MuiSelect: {
            root: {
                padding: '6px 8px 7px',
                border: ' 1px solid #DDDDDD',
            },
            selectMenu: {
                maxHeight: 100,
            },
            icon: {
                color: '#CCCCCC',
            },
        },
        MuiFormHelperText: {
            root: {
                fontSize: 14,
            },
        },
        MuiDivider: {
            root: {
                width: '100%',
            },
        },
        MuiCheckbox: {
            root: {
                color: '#CCCCCC',
            },
            colorPrimary: {
                '&$checked': {
                    color: MAIN_COLOR,
                },
            },
        },
        MuiChip: {
            root: {
                fontSize: 16,
                boxSizing: 'border-box',
                borderRadius: 17,
            },
            clickable: {
                border: '1px solid #DDDDDD',
                backgroundColor: '#FFFFFF',
                '&:focus': {
                    backgroundColor: '#FFFFFF',
                },
            },
            clickableColorPrimary: {
                backgroundColor: MAIN_COLOR,
                border: '1px solid #FFF',
                '&:hover': {
                    backgroundColor: 'rgba(81, 179, 189, 0.4)',
                },
                '&:focus': {
                    backgroundColor: MAIN_COLOR,
                },
            },
        },
        MuiCircularProgress: {
            colorPrimary: {
                color: MAIN_COLOR,
            },
        },
        MuiIconButton: {
            colorPrimary: {
                color: MAIN_COLOR,
                '&:hover': {
                    backgroundColor: 'rgba(81, 179, 189, 0.1)',
                },
            },
        },
        MuiButton: {
            root: {
                textTransform: 'none',
                fontWeight: 'bold',
                fontSize: 18,
            },
            containedPrimary: {
                backgroundColor: MAIN_COLOR,
                color: '#FFFFFF',
                '&:hover': {
                    backgroundColor: 'rgba(81, 179, 189, 0.4)',
                },
            },
            textPrimary: {
                color: MAIN_COLOR,
            },
            textSecondary: {
                color: '#CF5C36',
            },
            iconSizeMedium: {
                '& > *:first-child': {
                    fontSize: 24,
                },
            },
        },
        MuiInput: {
            root: {
                fontSize: 18,
            },
            underline: {
                '&$focused': {
                    borderBottom: '2px solid #51B3BD',
                },
                '&$focused$error': {
                    borderBottom: '2px solid red',
                },
                '&::after': {
                    borderBottom: '2px solid #51B3BD',
                },
                '&::before': {
                    borderBottom: '2px solid #949494',
                },
            },
        },
        MuiAppBar: {
            colorPrimary: {
                color: 'inherit',
                backgroundColor: '#FFFFFF',
            },
        },
        MuiDialog: {
            paperWidthSm: {
                maxWidth: 800,
                minWidth: 500,
                padding: 32,
            },
        },
        MuiPickersDay: {
            current: {
                color: MAIN_COLOR,
            },
            daySelected: {
                backgroundColor: MAIN_COLOR,
                '&:hover': {
                    backgroundColor: 'rgba(81, 179, 189, 0.4)',
                },
            },
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: MAIN_COLOR,
            },
        },
        MuiPickersYear: {
            yearSelected: {
                color: MAIN_COLOR,
                '&:focus': {
                    color: MAIN_COLOR,
                },
            },
        },
        MuiPickersMonth: {
            monthSelected: {
                color: MAIN_COLOR,
                '&:focus': {
                    color: MAIN_COLOR,
                },
            },
        },
        MuiPickersModal: {
            dialogRoot: {
                padding: 0,
            },
        },
    },
};
