import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/styles';
import ContactUsView from './ContactUsView';
import ContactUsDialog from '../popups/ContactUsDialog';

const styles = () => ({
    contactUsTitleContainer: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
    },
    closeButton: {
        position: 'absolute',
        right: 0,
    },
});

const ContactUsModal = props => (
    <ContactUsDialog isOpened={props.isOpened} onClickCancel={props.onClickCancel}>
        <Box className={props.classes.contactUsTitleContainer}>
            <Typography noWrap variant="h3">
                {l('Contact Us')}
            </Typography>
            <IconButton
                onClick={props.onClickCancel}
                aria-label="close"
                className={props.classes.closeButton}
            >
                <CloseIcon />
            </IconButton>
        </Box>
        <ContactUsView />
    </ContactUsDialog>
);

ContactUsModal.propTypes = {
    isOpened: PropTypes.bool.isRequired,
    onClickCancel: PropTypes.func.isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(ContactUsModal);
