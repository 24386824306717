import { fromJS } from 'immutable';

import { successAction, failAction, startAction } from 'actions/actionTypes';
import { LOGIN, LOGOUT, REFRESH_TOKEN, RESTORE_AUTH } from 'actions/session';
import { UPDATE_PROFILE } from 'actions/users';

const defaultState = fromJS({ isLoggingIn: false, isLoggedIn: false });

export default function session(state = defaultState, action) {
    switch (action.type) {
        case startAction(LOGIN):
            return fromJS({
                isLoggingIn: true,
                isLoggedIn: false,
            });
        case successAction(LOGIN): {
            return fromJS({
                isLoggingIn: false,
                isLoggedIn: true,
                appToken: action.response.data,
            });
        }
        case RESTORE_AUTH: {
            return fromJS({
                isLoggingIn: false,
                isLoggedIn: true,
                appToken: JSON.parse(action.appToken),
            });
        }
        case LOGOUT:
        case failAction(LOGIN):
        case failAction(REFRESH_TOKEN): {
            return fromJS({
                isLoggingIn: false,
                isLoggedIn: false,
            });
        }
        case successAction(REFRESH_TOKEN):
            return state.mergeIn(['appToken'], fromJS(action.response.data));
        case successAction(UPDATE_PROFILE):
            return state.mergeIn(['appToken', 'user'], fromJS(action.response.data));
        default:
            return state;
    }
}
