import { CALL_API } from 'middleware/api';

import { normalizeEntityResponse, normalizePagingResponse } from 'utils/converters';

import schemas from 'data/schemas';

import settings from 'settings';

export const GET_DEVICES = 'GET_DEVICES';
export const DELETE_DEVICE = 'DELETE_DEVICE';
export const UPDATE_DEVICE = 'UPDATE_DEVICE';

export const deleteDevice = deviceId => dispatch => (
    dispatch({
        deviceId,
        [CALL_API]: {
            type: DELETE_DEVICE,
            endpoint: `/api/v1/devices/${deviceId}`,
            method: 'delete',
        },
    })
);

export const updateDevice = device => dispatch => (
    dispatch({
        [CALL_API]: {
            type: UPDATE_DEVICE,
            endpoint: `/api/v1/devices/${device.id}`,
            method: 'patch',
            body: device,
            submitFormRequest: true,
            converter: () => normalizeEntityResponse(schemas.DEVICE)({ data: { ...device } }),
        },
    })
);

export const getDevicesData = (
    { params = {}, page = 1, limit = settings.tableEntitiesLimit },
) => dispatch => (
    dispatch({
        params: { page },
        withSpinner: page <= 1,
        [CALL_API]: {
            type: GET_DEVICES,
            endpoint: '/api/v1/devices',
            params: { ...params, page, limit },
            converter: normalizePagingResponse(schemas.DEVICES),
        },
    })
);
