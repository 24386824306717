import React, { memo, useCallback, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Field } from 'react-final-form';

import { withStyles } from '@material-ui/styles';

import { Button, TableCell, TableRow } from '@material-ui/core';
import TextField from 'inputs/forms/TextField';

import PromptConfirmationPopup from '../popups/PromptConfirmationDialog';

const styles = () => ({
    devicesCell: {
        padding: '0',
        textAlign: 'center',
        border: 0,
    },
    tableRow: {
        display: 'table',
        width: '100%',
    },
    devicesCellDelete: {
        textAlign: 'start',
    },
    birthDate: {
        display: 'inline-block',
    },
    patientsButton: {
        position: 'relative',
        bottom: 3,
    },
    deviceName: {
        padding: '16px 30px',
    },
});

const Device = props => {
    const handleDelete = useCallback(() => {
        props.onClickDelete(props.initialValues.id);
    }, [props.initialValues.id]);

    useEffect(() => () => props.onChangePristineForm({ pristine: true }), []);

    const handleLeave = useCallback(location => {
        if (!props.pristine && !props.submitSucceeded) {
            props.showPromptPopup(location);
            return false;
        }
        return true;
    }, [props.pristine, props.submitSucceeded]);

    useEffect(() => {
        if (props.dirty === true) {
            props.onChangePristineForm({ pristine: props.pristine });
        }
    }, [props.pristine]);

    return (
        <Fragment>
            {props.dirty === true && (
                <PromptConfirmationPopup
                    text={l('Are you sure you want to leave the screen without saving?')}
                    location={props.promptLocation}
                    isOpenPopup={props.isOpenPromptConfirmPopup}
                    onClickOk={props.onClickLeave}
                    onClickCancel={props.onClickStay}
                    onLeave={handleLeave}
                />
            )}
            <TableRow className={props.classes.tableRow}>
                <TableCell className={`${props.classes.devicesCell} ${props.classes.deviceName}`}>
                    <Field
                        name="name"
                        fullWidth
                        component={TextField}
                    />
                </TableCell>
                <TableCell className={props.classes.devicesCell}>
                    <Button
                        color="primary"
                        variant="text"
                        type="submit"
                        className={props.classes.patientsButton}
                        onClick={props.handleSubmit}
                    >
                        {l('Save')}
                    </Button>
                </TableCell>
                <TableCell className={`${props.classes.devicesCell} ${props.classes.devicesCellDelete}`}>
                    <Button
                        color="secondary"
                        variant="text"
                        className={props.classes.patientsButton}
                        onClick={handleDelete}
                    >
                        {l('Delete')}
                    </Button>
                </TableCell>
            </TableRow>
        </Fragment>
    );
};

Device.propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    initialValues: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    pristine: PropTypes.bool,
    dirty: PropTypes.bool,
    submitSucceeded: PropTypes.bool,
    promptLocation: ImmutablePropTypes.map,
    isOpenPromptConfirmPopup: PropTypes.bool.isRequired,
    onClickStay: PropTypes.func.isRequired,
    showPromptPopup: PropTypes.func.isRequired,
    onClickLeave: PropTypes.func.isRequired,
    onChangePristineForm: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,

};

export default withStyles(styles)(memo(Device));
