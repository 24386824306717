import { Map, fromJS } from 'immutable';
import { createSelector } from 'reselect';
import queryString from 'query-string';

export const getLocation = state => state.getIn(['router', 'location']);

export const getSearch = createSelector(
    getLocation,
    location => (location && location.get('search')) || '',
);

export const getQuery = createSelector(
    getSearch,
    search => (search && fromJS(
        queryString.parse(search, { arrayFormat: 'bracket-separator' }),
    )) || Map(),
);

export const getPathname = createSelector(
    getLocation,
    location => location && location.get('pathname'),
);

export const getRouteState = createSelector(
    getLocation,
    location => location && location.get('state'),
);
