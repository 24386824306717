import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router/immutable';

import { sendEmailResetPassword, sendNewPassword } from 'actions/resetPassword';

import SendEmail from 'components/reset-password/SendEmail';
import ResetPassword from 'components/reset-password/ResetPassword';

import { getEmail, getIsResending } from 'selectors/resetPassword';

import { LOGIN } from 'data/routes';

const mapStateToProps = state => ({
    email: getEmail(state),
    resending: getIsResending(state),
});

const mapDispatchToProps = { replace, sendEmailResetPassword, sendNewPassword };

const ResetPasswordContainer = props => {
    const handleClickSendEmail = useCallback(props.sendEmailResetPassword, [props.sendEmailResetPassword]);

    const handleClickResendEmail = useCallback(() => {
        props.sendEmailResetPassword({ email: props.email });
    }, [props.email, props.sendEmailResetPassword]);

    const handleClickChangePassword = useCallback(values => (
        props.sendNewPassword({ email: props.email, password: values.password, code: values.code })
    ), [props.email, props.sendNewPassword]);

    const handleClickCancel = useCallback(() => (
        props.replace(LOGIN)
    ), [props.replace]);

    return props.email ? (
        <ResetPassword
            resending={props.resending}
            onClickCancel={handleClickCancel}
            onClickResendEmail={handleClickResendEmail}
            onClickChangePassword={handleClickChangePassword}
        />
    ) : (
        <SendEmail
            onClickCancel={handleClickCancel}
            onClickSendEmail={handleClickSendEmail}
        />
    );
};

ResetPasswordContainer.propTypes = {
    email: PropTypes.string,
    resending: PropTypes.bool,
    replace: PropTypes.func.isRequired,
    sendNewPassword: PropTypes.func.isRequired,
    sendEmailResetPassword: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(ResetPasswordContainer));
