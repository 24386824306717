import React from 'react';
import PropTypes from 'prop-types';

import { Paper, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import BackgroundImg from 'assets/img/background.svg';

const styles = () => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        backgroundImage: `url(${BackgroundImg})`,
    },
    paper: {
        marginTop: 100,
        width: 600,
        padding: '48px 42px',
        boxShadow: '0 2px 8px 0 rgba(0,0,0,0.14)',
        borderRadius: 2,
    },
});

const ContactUsWrapper = props => (
    <Box className={props.classes.container}>
        <Paper className={props.classes.paper}>
            {props.children}
        </Paper>
    </Box>
);

ContactUsWrapper.propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    children: PropTypes.node.isRequired,
};

export default withStyles(styles)(ContactUsWrapper);
