import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { withStyles } from '@material-ui/core/styles';

import SnackbarMessage from 'components/messages/SnackbarMessage';

import { ERROR } from 'data/messages';

const styles = () => ({
    error: {
        position: 'absolute',
        top: 25,
        zIndex: 9999999,
        left: '50%',
        right: 'auto',
        transform: 'translateX(-50%)',
    },
    other: {
        position: 'fixed',
        bottom: 15,
        right: 15,
        zIndex: 9999999,
    },
});

const MessageStacks = props => {
    const errorMessage = props.messages.find(item => item.get('type') === ERROR);

    return (
        <Fragment>
            <div className={props.classes.error}>
                {
                    errorMessage && (
                        <SnackbarMessage
                            key={errorMessage.get('id')}
                            id={errorMessage.get('id')}
                            message={errorMessage.get('message')}
                            variant={errorMessage.get('type')}
                            onClose={props.hideMessage}
                        />
                    )
                }
            </div>
            <div className={props.classes.other}>
                {
                    props.messages.filterNot(item => item.get('type') === ERROR)
                        .map(item => (
                            <SnackbarMessage
                                key={item.get('id')}
                                id={item.get('id')}
                                message={item.get('message')}
                                variant={item.get('type')}
                                onClose={props.hideMessage}
                            />
                        ))
                }
            </div>
        </Fragment>
    );
};

MessageStacks.propTypes = {
    messages: ImmutablePropTypes.list,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    hideMessage: PropTypes.func.isRequired,
};

export default withStyles(styles)(MessageStacks);
