import { LOCATION_CHANGE } from 'connected-react-router/immutable';
import queryString from 'query-string';

import { getQuery } from 'selectors/router';

export default store => next => action => {
    const query = getQuery(store.getState());
    next(action);
    switch (action.type) {
        case LOCATION_CHANGE:
            if (query.get('search') === queryString.parse(
                action.payload.location.search,
                { arrayFormat: 'bracket-separator' },
            ).search) {
                window.scrollTo(0, 0);
            }
            break;
        default:
            break;
    }
};
