import { schema } from 'normalizr';

const patientSchema = new schema.Entity('patient');

const deviceSchema = new schema.Entity('device');

const recordSchema = new schema.Entity('record', { patient: patientSchema });

const doctorSchema = new schema.Entity('doctor');

export default {
    DEVICE: deviceSchema,
    DEVICES: [deviceSchema],
    RECORD: recordSchema,
    RECORDS: [recordSchema],
    PATIENT: patientSchema,
    PATIENTS: [patientSchema],
    DOCTOR: doctorSchema,
    DOCTORS: [doctorSchema],
};
