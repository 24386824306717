import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import moment from 'moment';

import { Button, TableCell, TableRow, Typography } from '@material-ui/core';
import { PersonAddOutlined as PersonAddOutlinedIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';

import { DATE } from 'utils/formatterUtils';

const styles = () => ({
    birthDate: {
        display: 'inline-block',
    },
    patientsButton: {
        position: 'relative',
        bottom: 3,
    },
    assign: {
        borderRadius: '16.5px',
        backgroundColor: 'rgba(86,160,172,0.15)',
        padding: '0px 18px',
    },
    assignIcon: {
        marginLeft: 4,
        height: 24,
        width: 24,
    },
    profileMargin: {
        marginLeft: 37,
    },
    shared: {
        color: '#919699',
        fontWeight: 'bold',
    },
});

const PatientRow = props => {
    const handleClickHistory = useCallback(e => {
        e.preventDefault();
        props.onClickHistory(props.patient);
    }, [props.patient, props.onClickHistory]);

    const handleClickAssign = useCallback(e => {
        e.stopPropagation();
        props.onClickAssign(props.patient);
    }, [props.patient, props.onClickAssign]);

    const handleClickProfile = useCallback(e => {
        e.stopPropagation();
        props.onClickProfile(props.patient);
    }, [props.patient, props.onClickAssign]);

    return (
        <TableRow>
            <TableCell>
                <Typography variant="body1" noWrap className={props.classes.fullNameBox}>
                    {props.patient.get('firstName')} {props.patient.get('lastName')}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="body1" noWrap>
                    {moment(props.patient.get('dateOfBirth')).format(DATE)}
                </Typography>
            </TableCell>
            <TableCell>
                {props.userId && props.patient.get('createdBy') !== props.userId && (
                    <Typography
                        variant="body1" color="primary" noWrap
                        className={props.classes.shared}
                    >
                        {l('Shared')}
                    </Typography>
                )}
            </TableCell>
            <TableCell>
                <Button
                    color="primary"
                    variant="text"
                    className={props.classes.patientsButton}
                    onClick={handleClickProfile}
                >
                    {l('See profile')}
                </Button>
            </TableCell>
            <TableCell>
                <Button
                    color="primary"
                    variant="text"
                    className={props.classes.patientsButton}
                    onClick={handleClickHistory}
                >
                    {l('History')}
                </Button>
            </TableCell>
            {
                props.withAssign && (
                    <TableCell>
                        <Button
                            color="primary"
                            variant="text"
                            startIcon={(
                                <PersonAddOutlinedIcon className={props.classes.assignIcon} />
                            )}
                            className={props.classes.assign}
                            onClick={handleClickAssign}
                        >
                            {l('Assign')}
                        </Button>
                    </TableCell>
                )
            }
        </TableRow>
    );
};

PatientRow.propTypes = {
    withAssign: PropTypes.bool,
    userId: PropTypes.string,
    patient: ImmutablePropTypes.map.isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onClickAssign: PropTypes.func.isRequired,
    onClickProfile: PropTypes.func.isRequired,
    onClickHistory: PropTypes.func.isRequired,
};

export default withStyles(styles)(memo(PatientRow));
