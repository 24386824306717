import { fromJS } from 'immutable';
import { LOCATION_CHANGE } from 'connected-react-router';

import { SEND_EMAIL_RESET_PASSWORD } from 'actions/resetPassword';
import { successAction, failAction, startAction } from 'actions/actionTypes';

const defaultState = fromJS({
    email: null,
    resending: false,
});

export default function resetPassword(state = defaultState, action) {
    switch (action.type) {
        case LOCATION_CHANGE:
            return defaultState;
        case startAction(SEND_EMAIL_RESET_PASSWORD):
            return state.set('resending', true);
        case successAction(SEND_EMAIL_RESET_PASSWORD):
            return state.merge({
                email: action.email,
                resending: false,
            });
        case failAction(SEND_EMAIL_RESET_PASSWORD):
            return state.set('resending', false);
        default:
            return state;
    }
}
